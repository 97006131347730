import { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import asc from "../../assets/images/asc.png";
import desc from "../../assets/images/desc.png";

let timer;
const MySearch = (props) => {
  const [inputSearch, setInputSearch] = useState("");

  const handleInputSearch = (e, search) => {
    const value = e.target.value;
    setInputSearch(value);
    timer && clearTimeout(timer);
    timer = setTimeout(() => search(value), 500);
  };

  return (
    <div>
      <input
        className="form-control"
        placeholder="Search in records..."
        onChange={(e) => {
          handleInputSearch(e, props.onSearch);
        }}
        value={inputSearch}
        onKeyDown={(e) => {
          if (inputSearch && e.key === "Enter") {
            props.onSearch(new String(inputSearch));
          }
        }}
        type="text"
      />
    </div>
  );
};

const PageButton = ({ disabled, onClick, label }) => (
  <li className={`page-item ${disabled ? "disabled" : ""}`}>
    <button className="page-link" onClick={onClick} disabled={disabled}>
      {label}
    </button>
  </li>
);

const StaticTable = ({
  data,
  columns,
  id,
  select = true,
  handleOnSelect = null,
  handleOnSelectAll = null,
}) => {
  const [sizePerPage, setSizePerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  // Update filtered data based on search
  useEffect(() => {
    if (searchValue) {
      const filtered = data.filter((item) =>
        Object.values(item).some((val) =>
          val?.toString().toLowerCase().includes(searchValue.toLowerCase())
        )
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(data);
    }
  }, [data, searchValue]);

  const totalRecords = filteredData.length;
  const totalPages = Math.ceil(totalRecords / sizePerPage);

  // Ensure current page doesn't go out of bounds when deleting
  useEffect(() => {
    if (page > totalPages && totalPages > 0) {
      setPage(totalPages);
    }
  }, [totalPages, page]);

  const sortOption = {
    sortCaret: (order) => {
      if (!order) return null;
      if (order === "asc")
        return (
          <span className="sort_symbol">
            <img src={asc} alt="asc" />
          </span>
        );
      if (order === "desc")
        return (
          <span className="sort_symbol">
            <img src={desc} alt="desc" />
          </span>
        );
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleSizePerPageChange = (newSizePerPage) => {
    setSizePerPage(newSizePerPage);
    setPage(1); // Reset to first page when changing size per page
  };

  const currentPageData = filteredData.slice(
    (page - 1) * sizePerPage,
    page * sizePerPage
  );

  const selectRowStatic = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };
  // Determine if we're on the first or last page
  const isFirstPage = page === 1;
  const isLastPage = page === totalPages || totalPages === 0;

  // Getting number of rows per page
  const rowsPerPage = sizePerPage;
  const startRow = (page - 1) * rowsPerPage + 1;
  const endRow = Math.min(page * rowsPerPage, data.length);

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="d-flex mb-3">
          <div className="input-group-addon input-group-prepend">
            <span className="input-group-text table_size_per_page_text">
              Show{" "}
            </span>
          </div>
          <select
            className="form-control table_size_per_page_input"
            onChange={(e) => handleSizePerPageChange(Number(e.target.value))}
          >
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={totalRecords}>All</option>
          </select>
          <div className="input-group-addon input-group-prepend">
            <span className="input-group-text table_size_per_page_text">
              records per page
            </span>
          </div>
        </div>

        <MySearch onSearch={setSearchValue} />
      </div>
      <BootstrapTable
        keyField={id}
        data={currentPageData}
        columns={columns}
        striped
        sort={sortOption}
        selectRow={select ? selectRowStatic : undefined}
      />
      {/* Pagination Controls */}
      <nav aria-label="Page navigation">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <span>
              Showing rows {startRow} to {endRow} of {data.length}
            </span>
          </div>
          <ul className="pagination mb-0">
            <PageButton
              disabled={isFirstPage}
              onClick={() => handlePageChange(1)}
              label="First"
            />
            <PageButton
              disabled={isFirstPage}
              onClick={() => handlePageChange(page - 1)}
              label="Previous"
            />
            <li className="page-item active">
              <span className="page-link">{page}</span>
            </li>
            <PageButton
              disabled={isLastPage}
              onClick={() => handlePageChange(page + 1)}
              label="Next"
            />
            <PageButton
              disabled={isLastPage}
              onClick={() => handlePageChange(totalPages)}
              label="Last"
            />
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default StaticTable;
