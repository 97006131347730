import React, { useEffect, useState, useContext } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { CloudDownloadOutlined, Print } from "@material-ui/icons";
import { defultReportPrintStyle, printDocument } from "../../utils/utils";
import FeedbackReportItem from "../player/FeedbackReportItem";
import { SSOContext } from "../../context/SSOContext";
import SimPlayedSummaryService from "../../services/SimPlayedSummaryService";
import PersonalInfo from "./PersonalInfo";
import PersonalInfoDialog from "../generic/PersonalInfoDialog";
import SimResultService from "../../services/SimResultService";
import jsPDF from "jspdf";
import Loader from "../loader";
import VariableOperationService from "../../services/VariableOperationService";

const EndTrack = (props) => {
  const [viewedListOfTrack, setViewedListOfTrack] = useState([]);
  const ssoContext = useContext(SSOContext);
  const [isOpen, setIsOpen] = useState(false);
  const [simPlayedSummary, setSimPlayedSummary] = useState({});
  const [simPlayedSummaryId, setSimPlayedSummaryId] = useState();
  const [isSavePdf, setIsSavePdf] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    props.setEndSimSession(true)
  }, []);

  useEffect(() => {
    const list = props.tracksViewedSummmary;
    const listOfTrackId = [];
    list.forEach((element) => {
      listOfTrackId.push(element.trackId);
    });
    setViewedListOfTrack(listOfTrackId);

    const responseData = {
      simId: props.simId,
      accId: ssoContext.accountId,
      linkId: ssoContext.linkId,
      linkAuditId: ssoContext.linkAuditId,
      portalSimCode: ssoContext.portalSimCode,
      viewedTracksList: JSON.stringify(listOfTrackId),
      simCode: props.simCode,
      playStartDate: props.simSessionStartDate
        ? props.simSessionStartDate
        : props.playDates.playStartDate,
      playEndDate: new Date(),
    };
    SimPlayedSummaryService()
      .saveSimPlayedSummaryResult([responseData])
      .then((response) => {
        if (response.status === 200) {
          setSimPlayedSummaryId(response.data[0]?.id);
          setSimPlayedSummary(response.data);
        } else {
          console.error(
            "Error: while saving sim played summary",
            response.message
          );
        }
      })
      .catch((e) => {
        console.error(e.message);
      });
  }, props.tracksListViewed);

  useEffect(() => {
    if (props.simResultList.length > 0 && simPlayedSummaryId) {
      let res = props.simResultList.map((element) => {
        return { ...element, simPlayedId: simPlayedSummaryId };
      });
      SimResultService().saveSimResult(res);
    }
  }, [props.simResultList, simPlayedSummaryId]);

  useEffect(() => {
    if (props.variableOperationList?.length > 0 && simPlayedSummaryId) {
      let varOperation = props.variableOperationList.map(
        (variableOperation) => {
          return {
            ...variableOperation,
            simPlayedSummaryId: simPlayedSummaryId,
          };
        }
      );
      VariableOperationService().saveVariableOperation(
        varOperation,
        "/variableOperation/save"
      );
    }
  }, [props.variableOperationList, simPlayedSummaryId]);

  const givePdf = (title, style) => {
    const div = document.getElementById("fullReport");
    printDocument(title, "Simulation Feedback Report", style, div.innerHTML);
  };

  const location = "Feedback Report";


  const printHandler = () => {
    setIsSavePdf(false);
    if (!simPlayedSummary?.firstName) {
      setIsOpen(true);
    } else {
      givePdf(location, defultReportPrintStyle);
    }
  };

  const savePdf = () => {
    setIsLoading(true);
    const doc = new jsPDF({
      orientation: "p",
      unit: "px",
      format: "a1",
      putOnlyUsedFonts: true,
      floatPrecision: 16,
    });
    doc.html(document.getElementById("pdf-report").innerHTML, {
      callback: (doc) => {
        doc.save("Simulation Feedback Report.pdf");
        setIsLoading(false);
      },
      autoPaging: "text",
      margin: [8, 80, 8, 80],
      width: 1100,
      windowWidth: 1100,
    });
    isSavePdf && setIsSavePdf(false);
  };

  const saveHandler = () => {
    if (!simPlayedSummary?.firstName) {
      setIsOpen(true);
      setIsSavePdf(true);
    } else {
      savePdf();
    }
  };

  const isAccreditationClient =
    ssoContext.accreditationClient === "false"
      ? false
      : ssoContext.accreditationClient;

  const reportMarkup = (isPdfMarkup) => {
    const markupId = isPdfMarkup ? "pdf-report" : "fullReport";
    const fontChange = isPdfMarkup ? "font-change" : "";
    return (
      <div id={markupId}>
        <div className={fontChange}>
          <h1>Simulation Feedback Report</h1>
          <div className="row mr-0">
            <div className="col-8">
              <p>
                Congratulations on completing the simulation. Let's take a look
                and see how you did!
              </p>
              {isAccreditationClient && (
                <div className="text-red">
                  Instructions: If you played this simulation for an assignment
                  or project and need to submit it, please click the save and
                  download button (cloud with an arrow), click the Yes button,
                  enter your information, and click Save and Download. You are
                  welcome to play the simulation multiple times and save once
                  you are happy with your results.
                </div>
              )}
            </div>
            <div className="col-4 text-right">
              <Tooltip title="Save Feedback Report" placement="bottom">
                <IconButton onClick={saveHandler}>
                  <CloudDownloadOutlined fontSize="large" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Print Feedback Report" placement="bottom">
                <IconButton onClick={printHandler}>
                  <Print fontSize="large" />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className="report">
            {simPlayedSummary?.firstName && (
              <PersonalInfo
                simPlayedSummary={simPlayedSummary}
                layout={isPdfMarkup}
              />
            )}
            <div className="report_item">
              <h2>Tracks Viewed Summary</h2>
              <div className="summary_main">
                {props.tracksViewedSummmary < 1 && "No tracks were played"}
                {props.tracksViewedSummmary.map((element, index) => {
                  let appendWhenNotLastElement = "=>";
                  if (index + 1 === props.tracksViewedSummmary.length) {
                    appendWhenNotLastElement = "";
                  }
                  let result = element?.title + " ( " + element?.order + " ) ";
                  return (
                    <>
                      <div className="report-item-summary">{result}</div>
                      <div style={{ display: "inline-block" }}>
                        {appendWhenNotLastElement}
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            {props.answersList < 1 && "No questions were asked"}
            {props.answersList.map((response, index) => {
              return <FeedbackReportItem {...response} key={index} />;
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {reportMarkup(false)}
      {reportMarkup(true)}
      {isOpen && (
        <PersonalInfoDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          cancel={`Cancel & ${isSavePdf ? "Download" : "Print"}`}
          save={`Save Info & ${isSavePdf ? "Download" : "Print"}`}
          title={"Personal Information"}
          simPlayedSummary={simPlayedSummary}
          setSimPlayedSummary={setSimPlayedSummary}
          isSavePdf={isSavePdf}
          pdf={(isSavePdf) => {
            if (isSavePdf) {
              savePdf();
            } else {
              givePdf(location, defultReportPrintStyle);
            }
          }}
          accountId={ssoContext.accountId}
          accreditationClient={
            ssoContext.accreditationClient === "false"
              ? false
              : ssoContext.accreditationClient
          }
        />
      )}
      {isLoading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
    </>
  );
};

export default EndTrack;
