import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  useContext,
} from "react";
import { UserContext } from "./UserContext";
import { LibraryContext } from "../context/LibraryContext";
import SimService from "../services/SimService";
import CharcterService from "../services/CharacterService";
import TrackService from "../services/TrackService";
import MediaService from "../services/MediaService";
import VariableService from "../services/VariableService";
import { isNullOrEmpty } from "../utils/utils";
import SimSessionService from "../services/SimSessionService";

export const PlayerContext = createContext();

//Provide a cached list of simulations in the library.
const PlayerContextProvider = (props) => {
  const [linkId, setLinkId] = useState(null);
  const [accountId, setAccountId] = useState(null);
  const [showPlayButton, setMShowPlayButton] = useState(false);
  const [showFlashAlert, setShowFlashAlert] = useState(true);
  const { user, token } = useContext(UserContext);
  const libraryContext = useContext(LibraryContext);
  const [isPlayingFromTrack, setIsPlayingFromTrack] = useState(false);
  const [isStoreSimSession, setIsStoreSimSession] = useState(false);
  const [simSessionState, setSimSessionState] = useState(null);
  const [isPlaySimLineraly, setIsPlaySimLineraly] = useState(false);

  const [playerState, setPlayerState] = useState({
    //DB Variables
    id: "",
    title: "",
    desc: "",
    time: 0,
    isLoading: true,
    published: false,
    archived: false,
    showCharacterIntro: false,
    showEmailReview: false,
    showSMSReview: false,
    showPhoneReview: false,
    createdDate: null,
    editDate: 0,
    characters: [],
    tracks: [],
    vars: [],
    media: [],
    current: null,
    answersList: [],
    curIndx: 0,
    viewedTrackList: [],
    showCharacterList: false,
    showInbox: false,
    showSMS: false,
    showCallLog: false,
    showWelcome: false,
    currentTrack: null,
    trackIdx: -1,
    currentCharacter: null,
    characterIdx: 0,
    responseList: [],
    callList: [],
    inbox: [],
    sms: [],
    playerBG: null,
    isComplete: false,
    simUserId: null,
    selectedOptions: [],
  });

  useEffect(() => {
    try {
      if (window.performance) {
        if (performance.navigation.type === 1) {
          if (playerState.id === "") {
            const localPlayerState = localStorage.getItem("playerState");

            if (localPlayerState) {
              const jsonLocalPlayerState = { ...JSON.parse(localPlayerState) };
              if (jsonLocalPlayerState.isStoreSimSession) {
                setIsStoreSimSession(jsonLocalPlayerState.isStoreSimSession);
                const simSession = {
                  userId: jsonLocalPlayerState.simUserId,
                  simId: jsonLocalPlayerState.simId,
                  linkId: jsonLocalPlayerState.linkId
                    ? jsonLocalPlayerState.linkId
                    : null,
                };
                SimSessionService()
                  .simNotPlayAgain(simSession)
                  .then((res) => {
                    console.debug(res);
                  });
              }
              console.log("Override player state with local storage state");
              setPlayerState(jsonLocalPlayerState);
            }
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAndPlaySim = (
    simId,
    linkId,
    linkAuditId,
    accountId,
    portalSimCode,
    showPreSimFeedbackPage,
    showPostSimFeedbackPage,
    showPreSimQR,
    showPostSimQR
  ) => {
    SimService()
      .getSimById(simId)
      .then((response) => {
        if (response.status === 200) {
          if (response.data !== null) {
            setLinkId(linkId);
            setSimToPlay(
              { ...response.data },
              true,
              linkId,
              linkAuditId,
              accountId,
              portalSimCode,
              showPreSimFeedbackPage,
              showPostSimFeedbackPage,
              showPreSimQR,
              showPostSimQR
            );
            libraryContext.addSimToLibrary(response.data);
          } else {
            console.error("Error: sim not found!");
          }
        } else {
          console.error("Error: while getting simulation!", response.message);
        }
      })
      .catch((error) => {
        console.debug(error);
      });
  };

  const setSimToPlay = (
    sim,
    isStoreSimSession = false,
    linkId = null,
    linkAuditId = null,
    accountId = null,
    portalSimCode = null,
    showPreSimFeedbackPage = false,
    showPostSimFeedbackPage = false,
    showPreSimQR = false,
    showPostSimQR = false
  ) => {
    setIsStoreSimSession(isStoreSimSession);
    //Load playerState
    if (token && !isNullOrEmpty(sim)) {
      Promise.all([
        TrackService().getTracksBySimId(sim.id, token),
        CharcterService().getCharctersBySimId(sim.id, token),
        MediaService().getMediasBySimId(sim.id, token),
        VariableService().getVariablesBySimId(sim.id, token),
      ])
        .then((values) => {
          console.log("Promises returned....", values);
          const [trackSnap, characterSnap, mediaSnap, varSnap] = values;

          let tracksList = trackSnap.data;
          let characterList = characterSnap.data;
          let mediaList = mediaSnap.data;
          let varsList = varSnap.data;

          if (isPlaySimLineraly) {
            tracksList.forEach((track) => {
              track.flashSettingId = null;
              track.flashTrack = false;
              track.flashTrackId = "";
              track.optionVisibiltySettings = [];
              track.responseEffects = [];
              track.responseLoops = [];
              track.visibilitySettings = [];
              track.parent = "";
              track.checkPriorOptions = false;
              track.checkUnavailableOptions = false;
            });
          }

          const playState = {
            ...{
              //DB Variables
              id: "",
              title: "",
              desc: "",
              time: 0,
              isLoading: true,
              published: false,
              archived: false,
              showActorIntro: false,
              showEmailReview: false,
              showSMSReview: false,
              showPhoneReview: false,
              createdDate: null,
              editDate: 0,
              characters: [],
              tracks: [],
              vars: [],
              media: [],
              current: null,
              answersList: [],
              curIndx: 0,
              viewedTrackList: [],
              showCharacterList: false,
              showCharacterIntro: false,
              showInbox: false,
              showSMS: false,
              showCallLog: false,
              showWelcome: false,
              currentTrack: null,
              trackIdx: -1,
              currentCharacter: null,
              characterIdx: 0,
              responseList: [],
              selectedOptions: [],
              callList: [],
              inbox: [],
              sms: [],
              playerBG: null,
              backgroundImgUrl: null,
              isComplete: false,
              // commented the showWelcome property,  because not to show the welcome track while playing the simulation
              // showWelcome: true
            },
            ...sim,
            isLoading: false,
            characters: characterList,
            media: mediaList,
            tracks: tracksList,
            vars: varsList,
            simResultId: null,
            simUserId: user.id,
            isStoreSimSession: isStoreSimSession,
            linkId: linkId,
            linkAuditId: linkAuditId,
            portalSimCode: portalSimCode,
            accountId: accountId,
            showPreSimFeedbackPage: showPreSimFeedbackPage,
            showPostSimFeedbackPage: showPostSimFeedbackPage,
            showPreSimQR: showPreSimQR,
            showPostSimQR: showPostSimQR,
          };
          setPlayerState(playState);
          setShowFlashAlert(true);
          try {
            localStorage.setItem("playerState", JSON.stringify(playState));
          } catch (error) {
            console.error(error.message);
          }
          console.log("Player State Load Complete");
        })
        .catch((e) => {
          console.log("Unable to load simulation...", e);
        });
    }
  };

  const setShowPlayButton = (canShow, exception = "") => {
    console.debug(
      String(exception).includes("denied") ||
        String(exception).includes("permission") ||
        String(exception).includes("interact") ||
        String(exception).includes("aborted"),
      "CanShow ex value :" + String(exception)
    );
    if (
      canShow === true &&
      (String(exception).includes("denied") ||
        String(exception).includes("permission") ||
        String(exception).includes("interact") ||
        String(exception).includes("aborted"))
    ) {
      setMShowPlayButton(true);
      return;
    }

    setMShowPlayButton(false);
  };

  const simPlayerProviderValue = useMemo(
    () =>
      // eslint-disable-next-line react-hooks/exhaustive-deps
      ({
        playerState,
        setPlayerState,
        simSessionState,
        setSimSessionState,
        isStoreSimSession,
        setSimToPlay,
        getAndPlaySim,
        linkId,
        setLinkId,
        accountId,
        setAccountId,
        showPlayButton,
        setShowPlayButton,
        showFlashAlert,
        setShowFlashAlert,
        isPlayingFromTrack,
        setIsPlayingFromTrack,
        isPlaySimLineraly,
        setIsPlaySimLineraly,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      playerState,
      setPlayerState,
      showPlayButton,
      setShowPlayButton,
      isPlayingFromTrack,
    ]
  );

  return (
    <PlayerContext.Provider value={simPlayerProviderValue}>
      {props.children}
    </PlayerContext.Provider>
  );
};

export default PlayerContextProvider;
