import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./Login";
import NotFound from "./NotFound";
import Dashboard from "./Dashboard";
import Users from "./users/users";
import AppContainer from "./AppContainer";
import AssetManager from "./AssetManager";
import PubSimCreator from "./PubSimCreator";
import InfoPage from "./InfoPage";
import SimulationLibrary from "./SimulationLibrary";
import LibraryContextProvider from "../context/LibraryContext";
import SimBuilder from "./builder/SimBuilder";
import SimLoading from "./SimLoading";
import EditSimContextProvider from "../context/EditSimContext";
import Player from "./player/Player";
import { UserContext } from "../context/UserContext";
import VariableContextProvider from "../context/VariableContext";
import MediaContextProvider from "../context/MediaContext";
import PlayerContextProvider from "../context/PlayerContext";
import CharacterContextProvider from "../context/CharacterContext";
import ProtectedRoute from "./ProtectedRoute";
import SimIdeasContextProvider from "../context/SimIdeasContext";
import SimFoldersContextProvider from "../context/SimFoldersContext";
import SimIdeas from "./simIdeas/SimIdeas";
import ViewSimIdea from "./simIdeas/ViewIdea";
import EditSimIdea from "./simIdeas/EditSimIdea";
import ViewDocs from "./simIdeas/ViewDocs";
import UsersContextProvider from "../context/UsersContext";
import { createBrowserHistory } from "history";
import PromtPlaySimulation from "./generic/PromtPlaySimulation";
import EditTrackContextProvider from "../context/EditTrackContext";
import InternalSimulations from "./exportInternalSimulation/InternalSimulations";
import AddInternalSim from "./exportInternalSimulation/AddInternalSim";
import ViewInternalSim from "./exportInternalSimulation/ViewInternalSim";
import CreateOrUpdateCode from "./codes/CreateOrUpdateCode";
import Codes from "./codes/Codes";
import SessionExpired from "./generic/SessionExpired";
import PreSimFeedback from "./player/SimFeedback/PreFeedback";
import PostSimFeedback from "./player/SimFeedback/PostSimFeedback";
import FeedbackSuccess from "./generic/FeedbackSuccess";

const App = () => {
  const PUB_PAGE = "/publicSimCreator";
  const ctx = useContext(UserContext);
  const loc = window.location;
  const history = createBrowserHistory();
  const PUB_PAGES = [
    "/session-expired",
    "/public/pre-feedback/",
    "/public/post-feedback/",
    "/feedback",
    "/publicSimCreator",
  ];
  // Check if the current path starts with any of the public pages
  const isPublicPage = PUB_PAGES.some((page) => loc.pathname.startsWith(page));

  return (
    <>
      {!ctx.user && !isPublicPage && <Login />}
      {loc.pathname === PUB_PAGE && <PubSimCreator />}
      {ctx.user && ctx.user.status && ctx.user.active && (
        <Router history={history}>
          <AppContainer>
            <SimFoldersContextProvider>
              <LibraryContextProvider>
                <UsersContextProvider>
                  <PlayerContextProvider>
                    <EditSimContextProvider>
                      <EditTrackContextProvider>
                        <VariableContextProvider>
                          <CharacterContextProvider>
                            <MediaContextProvider>
                              <SimIdeasContextProvider>
                                <Switch>
                                  <ProtectedRoute exact path="/" uContext={ctx}>
                                    <Dashboard />
                                  </ProtectedRoute>
                                  <Route path="/play">
                                    <Player />
                                  </Route>
                                  <ProtectedRoute
                                    path="/dashboard"
                                    uContext={ctx}
                                  >
                                    <Dashboard />
                                  </ProtectedRoute>
                                  <ProtectedRoute path="/users" uContext={ctx}>
                                    <Users />
                                  </ProtectedRoute>
                                  <ProtectedRoute path="/assets" uContext={ctx}>
                                    <AssetManager />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/library"
                                    uContext={ctx}
                                  >
                                    <SimulationLibrary />
                                  </ProtectedRoute>
                                  <ProtectedRoute path="/tracks" uContext={ctx}>
                                    <SimBuilder currentTab="Tracks" />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/builder"
                                    uContext={ctx}
                                  >
                                    <SimBuilder />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/simIdeas"
                                    uContext={ctx}
                                  >
                                    <SimIdeas />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/viewSimIdea"
                                    uContext={ctx}
                                  >
                                    <ViewSimIdea />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/editSimIdea"
                                    uContext={ctx}
                                  >
                                    <EditSimIdea />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/viewDocs"
                                    uContext={ctx}
                                  >
                                    <ViewDocs />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/internalSimulations"
                                    uContext={ctx}
                                  >
                                    <InternalSimulations />
                                  </ProtectedRoute>
                                  <ProtectedRoute
                                    path="/addInternalSim"
                                    uContext={ctx}
                                  >
                                    <AddInternalSim />
                                  </ProtectedRoute>
                                  <ProtectedRoute path="/codes" uContext={ctx}>
                                    <Codes />
                                  </ProtectedRoute>

                                  <Route path="/addCode" uContext={ctx}>
                                    <CreateOrUpdateCode />
                                  </Route>
                                  <Route
                                    path="/editCode/:codeId"
                                    uContext={ctx}
                                  >
                                    <CreateOrUpdateCode />
                                  </Route>
                                  <Route
                                    path="/viewInternalSim/:simId"
                                    component={ViewInternalSim}
                                    uContext={ctx}
                                  />
                                  <Route exact path="/sso">
                                    <SimLoading />
                                  </Route>
                                  <Route path="/loading">
                                    <SimLoading />
                                  </Route>
                                  <Route path="/promptPlay">
                                    <PromtPlaySimulation />
                                  </Route>
                                  <Route path="?">
                                    <NotFound />
                                  </Route>
                                  <Route path="/infoPage">
                                    <InfoPage />
                                  </Route>
                                </Switch>
                              </SimIdeasContextProvider>
                            </MediaContextProvider>
                          </CharacterContextProvider>
                        </VariableContextProvider>
                      </EditTrackContextProvider>
                    </EditSimContextProvider>
                  </PlayerContextProvider>
                </UsersContextProvider>
              </LibraryContextProvider>
            </SimFoldersContextProvider>
          </AppContainer>
        </Router>
      )}
      <Router history={history}>
        <Switch>
          <Route path="/session-expired" component={SessionExpired} />
          <Route
            path="/public/pre-feedback/:linkId/:linkAuditId"
            component={PreSimFeedback}
          />
          <Route
            path="/public/post-feedback/:linkId/:linkAuditId"
            component={PostSimFeedback}
          />
          <Route path="/feedback" component={FeedbackSuccess} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
