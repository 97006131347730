import React from "react";
import { useLocation } from "react-router-dom";
import "../styles/bootstrap.min.css";
import "../styles/style.css";
import BackToLibrary from "./BackToLibrary";
import LeftNavMenu from "./LeftNavMenu";
import TopNavMenu from "./TopNavMenu";
import useExpandCollapse from "../hooks/useExpandCollapse";

// URLs that should hide the menu
const canHideMenuPaths = [
  "/play",
  "/sso",
  "/publicsimcreator",
  "/view",
  "/loading",
  "/promptplay",
  "/infopage",
  "/preview",
  "/session-expired", "/feedback"
];

const canHideMainClass = ["/session-expired", "/feedback"];

const AppContainer = (props) => {
  const location = useLocation();
  const [isExpand, markup] = useExpandCollapse({ vertical: true });
  let pathName = location.pathname.toLowerCase();

  const canHideMenu =
    canHideMenuPaths.includes(pathName) ||
    pathName.includes("/public/pre-feedback") ||
    pathName.includes("/public/post-feedback");

  const canHideMain = canHideMainClass.includes(pathName);

  const leftNavClass = isExpand ? "leftNav" : "leftNav leftNav_width";
  const contentClass = isExpand ? "content" : "content content_left_position";

  return (
    <>
      <div className={canHideMenu ? "hidden" : leftNavClass}>
        <div className="leftNav_content">
          <LeftNavMenu isExpand={isExpand} markup={markup} />
        </div>
      </div>

      {location.pathname === "/play" && <BackToLibrary />}

      <div className={canHideMain ? "" : "main"}>
        <div className={canHideMenu ? "hidden" : "topNav"}>
          <TopNavMenu />
        </div>
        <div className={canHideMenu ? "fullContent" : contentClass}>
          {props.children}
        </div>
      </div>
    </>
  );
};

export default AppContainer;
