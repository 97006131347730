import React from "react";
import TopNavForPublic from "./TopNavForPublic";

const FeedbackSuccess = () => {
  return (
    <>
      <link href="/simFeedback.css" rel="stylesheet" />
      <TopNavForPublic />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <section className="simulation-header p-0">
              <h3 className="title">Simulation Feedback</h3>
            </section>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body text-center">
                <h3>Thanks for your feedback</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedbackSuccess;
