import TopNavForPublic from "../../generic/TopNavForPublic";
import { Rating } from "@material-ui/lab";
import React, { useState } from "react";

import Loader from "../../loader";
import { useParams } from "react-router-dom";
import { SB_URL } from "../../../Config";
import HttpService from "../../../services/HttpService";
import { v4 as uuidv4 } from "uuid";
import { ratingIcons, smileyIcons } from "../../../utils/utils";

const initialState = {
  postQ1Rating: 0,
  postQ2Rating: 0,
  postQ3Rating: 0,
  postQ4Rating: 0,
  postQ5Rating: 0,
  postQ6Rating: 0,
  postQ7Rating: 0,
  postQ8Rating: 0,
  postQ9Text: null,
  postQ10Text: null,
  postQ11Text: null,
  postQ12Text: null,
  postQ13Text: null,
};

const PostSimFeedback = (props) => {
  const [feedbackQuestions, setFeedbackQuestions] = useState({
    ...initialState,
  });

  const { linkId, linkAuditId } = useParams();

  const [customIconsForSmiley] = useState(smileyIcons);
  const [customIconsForRating] = useState(ratingIcons);
  const [isShowLoader, setShowLoader] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFeedbackQuestions((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleRatingChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    value = value == feedbackQuestions[name] ? 0 : value;
    setFeedbackQuestions((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const IconContainer = (props, name, useIcons) => {
    const { value, ...other } = props;
    const classes = `icons-label ${
      feedbackQuestions[name] == value ? "active" : ""
    }`;
    return (
      <span {...other} className={classes}>
        {useIcons[value].icon}
      </span>
    );
  };

  const submitFeedback = (e) => {
    //reset the condition of showing the pre and post sim feedback page if the post sim feedback is submitted
    setFormSubmitted(true);
    if (
      feedbackQuestions.postQ1Rating === 0 ||
      feedbackQuestions.postQ6Rating === 0
    ) {
      return;
    }
    setShowLoader(true);
    let savedSimFeedback = {};
    if (linkId) {
      savedSimFeedback = {
        ...JSON.parse(localStorage.getItem("simPreFeedback")),
      };
      savedSimFeedback =
        linkId == savedSimFeedback?.linkId ? savedSimFeedback : {feedbackUuid: uuidv4()};
    } else {
      savedSimFeedback = { ...props.simPreFeedback };
    }
    const simFeedback = {
      ...savedSimFeedback,
      ...feedbackQuestions,
      linkId: props.linkId || linkId,
      linkAuditId: props.linkAuditId || linkAuditId,
      postSubmitted: true,
    };

    HttpService()
      .postRequest(simFeedback, "/feedback")
      .then((response) => {
        if (response.status !== 200) {
          console.debug("Error: ", response.message);
        } else {
          const showPreSimFeedbackPage = false;
          const showPostSimFeedbackPage = false;
          let playerState = {
            ...JSON.parse(localStorage.getItem("playerState")),
          };

          playerState = {
            ...playerState,
            showPreSimFeedbackPage,
            showPostSimFeedbackPage,
          };
          if (linkId) {
            localStorage.removeItem("simPreFeedback");
            window.location.href = `${SB_URL}/feedback`;
          } else {
            localStorage.setItem(
              "showPreSimFeedbackPage",
              showPreSimFeedbackPage
            );
            localStorage.setItem(
              "showPostSimFeedbackPage",
              showPostSimFeedbackPage
            );
            localStorage.setItem("playerState", JSON.stringify(playerState));
            props.handleShowPostSimFeedbackPage(false);
          }
        }
        setShowLoader(false);
      })
      .catch((e) => {
        console.debug("Error while saving post sim feedback", e.message);
      });
  };

  return (
    <>
      {isShowLoader && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <link href="/simFeedback.css" rel="stylesheet" />
      <TopNavForPublic />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <section className="simulation-header p-0">
              <h3 className="title">Post Simulation Feedback</h3>
            </section>
          </div>
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                {!(linkId || linkAuditId) && (
                  <div className="mb-3 text-red">
                    Instructions: Please respond to the below questions. After
                    responding, you will hit the Submit button below and you
                    will be directed to the Feedback Report, which you can save
                    using the cloud icon with the arrow or print using the
                    printer icon
                  </div>
                )}

                <div>
                  <h5 className="required">
                    Please rate your simulation experience using the below
                    emotions
                  </h5>

                  <div className="form-group row mt-5">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="postQ1Rating"
                            defaultValue={0}
                            onChange={(event) => handleRatingChange(event)}
                            getLabelText={(value) =>
                              customIconsForSmiley[value].label
                            }
                            IconContainerComponent={(props) => {
                              return IconContainer(
                                props,
                                "postQ1Rating",
                                customIconsForSmiley
                              );
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"col-12"}>
                      {isFormSubmitted &&
                        feedbackQuestions.postQ1Rating === 0 && (
                          <p style={{ color: "red" }}>
                            {"The simulation experience field is required"}
                          </p>
                        )}
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">
                    I feel more confident in handling a future situation like
                    this one.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="postQ2Rating"
                            defaultValue={0}
                            onChange={(event) => handleRatingChange(event)}
                            getLabelText={(value) =>
                              customIconsForRating[value].label
                            }
                            IconContainerComponent={(props) => {
                              return IconContainer(
                                props,
                                "postQ2Rating",
                                customIconsForRating
                              );
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">
                    The situation was more complicated than I thought at first;
                    I noticed more factors at play by the end.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="postQ3Rating"
                            defaultValue={0}
                            onChange={(event) => handleRatingChange(event)}
                            getLabelText={(value) =>
                              customIconsForRating[value].label
                            }
                            IconContainerComponent={(props) => {
                              return IconContainer(
                                props,
                                "postQ3Rating",
                                customIconsForRating
                              );
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">
                    I am more aware of differing perspectives my peers might
                    have.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="postQ4Rating"
                            defaultValue={0}
                            onChange={(event) => handleRatingChange(event)}
                            getLabelText={(value) =>
                              customIconsForRating[value].label
                            }
                            IconContainerComponent={(props) => {
                              return IconContainer(
                                props,
                                "postQ4Rating",
                                customIconsForRating
                              );
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">
                    I am more aware of how I think about my decisions.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="postQ5Rating"
                            defaultValue={0}
                            onChange={(event) => handleRatingChange(event)}
                            getLabelText={(value) =>
                              customIconsForRating[value].label
                            }
                            IconContainerComponent={(props) => {
                              return IconContainer(
                                props,
                                "postQ5Rating",
                                customIconsForRating
                              );
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="required">This relates to my work</h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="postQ6Rating"
                            defaultValue={0}
                            onChange={(event) => handleRatingChange(event)}
                            getLabelText={(value) =>
                              customIconsForRating[value].label
                            }
                            IconContainerComponent={(props) => {
                              return IconContainer(
                                props,
                                "postQ6Rating",
                                customIconsForRating
                              );
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                    <div className={"col-12"}>
                      {isFormSubmitted &&
                        feedbackQuestions.postQ6Rating == 0 && (
                          <p style={{ color: "red" }}>
                            {"The relates to my work field is required"}
                          </p>
                        )}
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">
                    This will affect how I think about my work.
                  </h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="postQ7Rating"
                            defaultValue={0}
                            onChange={(event) => handleRatingChange(event)}
                            getLabelText={(value) =>
                              customIconsForRating[value].label
                            }
                            IconContainerComponent={(props) => {
                              return IconContainer(
                                props,
                                "postQ7Rating",
                                customIconsForRating
                              );
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="">This will affect what I do in my work.</h5>

                  <div className="form-group row mt-md-5 mt-3">
                    <div className="col-sm-8 offset-sm-3 text-center">
                      <div className="row">
                        <div className="col-sm-12 col-md-9">
                          <Rating
                            className="large-rating-icons"
                            name="postQ8Rating"
                            defaultValue={0}
                            onChange={(event) => handleRatingChange(event)}
                            getLabelText={(value) =>
                              customIconsForRating[value].label
                            }
                            IconContainerComponent={(props) => {
                              return IconContainer(
                                props,
                                "postQ8Rating",
                                customIconsForRating
                              );
                            }}
                            size="large"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-12 form-control-label">
                    What was your favorite part of this simulation experience?
                  </label>
                  <div className="col-sm-12">
                    <textarea
                      name="postQ9Text"
                      rows={5}
                      maxLength={5000}
                      className="form-control"
                      value={feedbackQuestions.questionOne}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 form-control-label">
                    What would you change about this simulation experience?
                  </label>
                  <div className="col-sm-12">
                    <textarea
                      name="postQ10Text"
                      rows={5}
                      maxLength={5000}
                      className="form-control"
                      value={feedbackQuestions.questionTwo}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 form-control-label">
                    Please describe the relevance of this simulation to your
                    specific course, professional development goals, or other
                    experiences?
                  </label>
                  <div className="col-sm-12">
                    <textarea
                      name="postQ11Text"
                      rows={5}
                      maxLength={5000}
                      className="form-control"
                      value={feedbackQuestions.questionThree}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 form-control-label">
                    In what ways were you challenged?
                  </label>
                  <div className="col-sm-12">
                    <textarea
                      name="postQ12Text"
                      rows={5}
                      maxLength={5000}
                      className="form-control"
                      value={feedbackQuestions.questionFour}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-sm-12 form-control-label">
                    Explain how your perspective regarding the decision-making
                    process may have been changed in light of this simulation
                    experience. Before and after?
                  </label>
                  <div className="col-sm-12">
                    <textarea
                      name="postQ13Text"
                      rows={5}
                      maxLength={5000}
                      className="form-control"
                      value={feedbackQuestions.questionFive}
                      onChange={(event) => handleChange(event)}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12 text-center">
                    <button
                      type="button"
                      className="btn white float-right"
                      onClick={submitFeedback}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PostSimFeedback;
