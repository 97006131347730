import logo from "./../../assets/images/logo.png"
import {Link, Tooltip} from "@material-ui/core";
import {HELP_PAGE_LINK} from "../../utils/Constant";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import React from "react";

const TopNavForPublic = ()=>{
    return (
        <div className="topNav_public">
            <div className="row nav-bar">
                <div className="logo_container">
                    <img src={logo} alt="School Sim Logo"/>
                </div>
            </div>
        </div>
    );
}

export default TopNavForPublic;