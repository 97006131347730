import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import _ from "lodash";
import Video from "./VideoTrack";
import Text from "./TextTrack";
import Question from "./QuestionResponseTrack";
import End from "./EndTrack";
import FreeText from "./FreeTextResponseTrack";
import Email from "./EmailTrack";
import SMS from "./SMSTrack";
import { ELS_PORTAL_URL, TEXT_DELIMITER, SB_URL } from "../../Config";
import { PlayerContext } from "../../context/PlayerContext";
import { LibraryContext } from "../../context/LibraryContext";
import {
  IconButton,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import Character from "./Character";
import PhoneTrack from "./PhoneTrack";
import Welcome from "./Welcome";
import CharacterPopupList from "./CharacterPopupList";
import MediaPopupList from "./MediaPopupList";
import Inbox from "./Inbox";
import RecentCalls from "./RecentCalls";
import PDFTrack from "./PDFTrack";
import Scorecard from "./Scorecard";
import PlayButton from "@material-ui/icons/PlayCircleFilledOutlined";
import { UserContext } from "../../context/UserContext";
import SimResultService from "../../services/SimResultService";
import S3Service from "../../services/S3Service";
import { SSOContext } from "../../context/SSOContext";
import CustomizedIcons from "../generic/CustomizedIcons";
import CharacterIcon from "../../assets/images/Characters.svg";
import DocumentsIcon from "../../assets/images/Documents.svg";
import MailIcon from "../../assets/images/Email.svg";
import SMSIcon from "../../assets/images/SMS.svg";
import PhoneIcon from "../../assets/images/PhoneCall.svg";
import HelpIcon from "../../assets/images/Help.svg";
import VolumeIcon from "../../assets/images/Volume.svg";
import VolumeMute from "../../assets/images/Volume-mute.svg";
import RestartIcon from "../../assets/images/RestartIcon.svg";
import CloseIcon from "../../assets/images/CloseIcon.svg";
import LeftArrow from "@material-ui/icons/ArrowBackRounded";
import {
  evaluateVariable,
  findMatchingId,
  isNullOrEmpty,
  setSimState,
} from "../../utils/utils";
import SimpleReactValidator from "simple-react-validator";
import SMSInbox from "./SMSInbox";
import SimulationProgress from "./SimulationProgress";
import SimSessionService from "../../services/SimSessionService";
import Loader from "react-loader-spinner";
import { HELP_PAGE_LINK } from "../../utils/Constant";
import CkEditorReadOnly from "../third-party/CkEditorReadOnly";
import VariableOperationService from "../../services/VariableOperationService";
import PreSimFeedback from "./SimFeedback/PreFeedback";
import PostSimFeedback from "./SimFeedback/PostSimFeedback";
import QRCodeGenerator from "../generic/QRCodeGenerator";

let startDate = null;
let fileSavedFlag = -1;
let isSimSessionSave = false;
let viewTrackListData = false;

const Player = () => {
  const SIM_FEEDBACK_TH = 5; //set in minutes
  const {
    playerState,
    setPlayerState,
    setSimToPlay,
    setLinkId,
    showPlayButton,
    setShowPlayButton,
    setShowFlashAlert,
    isStoreSimSession,
    simSessionState,
  } = useContext(PlayerContext);
  const ssoContext = useContext(SSOContext);
  const libraryContext = useContext(LibraryContext);
  const userContext = useContext(UserContext);
  const [showResetPopup, setShowResetPopup] = useState(false);
  const [showCloseSimPopup, setShowCloseSimPopup] = useState(false);
  const [simPlayTime, setSimPlayTime] = useState(null);
  const [isVolumeMute, setIsVolumeMute] = useState(false);
  const [play, setPlay] = useState(false);
  const [lastTrackIdx, setLastTrackIdx] = useState([]);
  const [isShowMediaList, setIsShowMediaList] = useState(false);
  const [url, setUrl] = useState("");
  const currentAnchorTag = useRef("");
  const simpleValidator = useRef(new SimpleReactValidator());
  const [timeOutSlide, setTimeOutSlide] = useState(null);
  const [isFromBackTrack, setIsFromBackTrack] = useState(false);
  const [tracksViewedSummmary, setTracksViewedSummary] = useState(
    simSessionState
      ? JSON.parse(simSessionState.simSessionData).tracksViewedSummmary
      : []
  );
  const [adContent, setAdContent] = useState(localStorage.getItem("adContent"));
  const [simResult, setSimResult] = useState(
    simSessionState
      ? JSON.parse(simSessionState.simSessionData).simResultData
      : []
  );
  const [saveFlag, setSaveFlag] = useState(
    simSessionState ? simSessionState.saveFlag : 0
  );
  const [savedFlag, setSavedFlag] = useState(-1);
  const trackIndex = useRef(-1);
  const [isSimResultData, setIsSimResultData] = useState(false);
  const [timer, setTimer] = useState();
  const [simSession, setSimSession] = useState(simSessionState);
  const [simPlayStartAndEndDate, setSimPlayStartAndEndDate] = useState({
    playStartDate: new Date(),
  });
  const [isLoaderOpen, setIsLoaderOpen] = useState(false);
  const [restartSimulation, setRestartSimulation] = useState(false);
  const [variableOperationList, setVariableOperationList] = useState([]);
  const [qrExpiryTime, setQRExpiryTime] = useState(
    new Date().getTime() + 8 * 60 * 60 * 1000
  ); // expiry set to 8 hours when sim is played

  const [endSimSession, setEndSimSession] = useState(false);

  // Get the current time
  const currentTime = new Date();

  useEffect(() => {
    if (isStoreSimSession) {
      if (savedFlag === -100) {
        setSaveFlag(savedFlag);
      }
      if (saveFlag === savedFlag) {
        setIsLoaderOpen(false);
        return;
      }
      const handleBeforeUnload = (event) => {
        setIsLoaderOpen(true);
        event.preventDefault();
        return (event.returnValue = "");
      };
      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }
  }, [saveFlag, savedFlag]);

  useEffect(() => {
    return () => {
      startDate = null;
      fileSavedFlag = -1;
      isSimSessionSave = false;
      viewTrackListData = false;
    };
  }, []);

  useEffect(() => {
    if (startDate == null && simSession) {
      const storedSimSessionState = JSON.parse(simSession?.simSessionData);
      startDate = storedSimSessionState.playStartDate;
    }
  }, [simSession]);

  const simCloseHandler = () => {
    if (isStoreSimSession && saveFlag !== savedFlag) {
      setIsLoaderOpen(true);
      return;
    }
    setShowCloseSimPopup(true);
  };

  //Handling slideTimeOut
  const handleSlideTimeOut = () => {
    if (timeOutSlide) {
      clearTimeout(timeOutSlide);
    }
    let idFromSlideTimeOut = setTimeout(() => {
      handleNextSlide();
    }, playerState.currentTrack.slideTimeout * 1000);
    setTimeOutSlide(idFromSlideTimeOut);
  };

  //Handling HyperLink of Editor
  const handleUserClick = useCallback((e) => {
    //checking if current tag is Anchor then not get parent element
    let currentElement =
      e.target.tagName == "A" ? e.target : e.target.parentElement;
    if (currentElement != null && currentElement != undefined) {
      currentAnchorTag.current = currentElement;
      let anchorTagAttr = currentElement.attributes;
      if (
        anchorTagAttr != undefined &&
        anchorTagAttr.getNamedItem("href") != undefined &&
        (anchorTagAttr.getNamedItem("id") == null ||
          anchorTagAttr.getNamedItem("id") == undefined)
      ) {
        let url = anchorTagAttr.getNamedItem("href").value;
        if (
          url != null &&
          url != undefined &&
          url != "" &&
          !simpleValidator.current.check(url, "url")
        ) {
          e.preventDefault();
          if (
            url.includes(TEXT_DELIMITER) &&
            url.split(TEXT_DELIMITER)[1] !== undefined
          ) {
            let objectKey =
              document.getElementById("currentSimId").value +
              "/" +
              url.split(TEXT_DELIMITER)[1];
            S3Service()
              .getSigendUrlForView(objectKey, "pdf", userContext.token)
              .then((response) => {
                if (response.status === 200) {
                  url = response.data;
                  currentElement.setAttribute("href", url);
                  setUrl(url);
                } else if (response.status === 404) {
                  console.error(response.message);
                  const msg = `The file (${
                    url.split(TEXT_DELIMITER)[0]
                  }) you requested may have been deleted, removed, or not available. Please contact the administrator.`;
                  window.open(`/infoPage?msg=${msg}`, "_blank");
                } else if (response.status === 500) {
                  const msg = `Internal server error, Please try later or contact the administrator.`;
                  window.open(`/infoPage?msg=${msg}`, "_blank");
                }
              })
              .catch((e) => {
                console.error("Error: while getting sigend url,", e.message);
              });
          } else {
            const msg = `The URL (${url}) is not valid. Please contact the administrator.`;
            window.open(`/infoPage?msg=${msg}`, "_blank");
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Binding/UnBinding click event
  useEffect(() => {
    document.addEventListener("click", handleUserClick);
    return () => {
      document.removeEventListener("click", handleUserClick);
    };
  }, [handleUserClick]);

  useEffect(() => {
    if (url !== "") {
      currentAnchorTag.current.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const resetAndPlayAgain = () => {
    const sim = libraryContext.getFullSim(playerState.id);
    setLastTrackIdx([]);
    if (isStoreSimSession) {
      SimSessionService()
        .simNotPlayAgain(simSession)
        .then((res) => {
          console.debug(res);
        });
      setSimSession(null);
      setSaveFlag(0);
      setTracksViewedSummary([]);
      setSimResult([]);
      setVariableOperationList([]);
      setSavedFlag(-1);
      setSimPlayStartAndEndDate({ playStartDate: new Date() });
      startDate = null;
      fileSavedFlag = -1;
      isSimSessionSave = false;
      viewTrackListData = false;
    }
    const accountId = JSON.parse(localStorage.getItem("accountId"));
    const linkAuditId = JSON.parse(localStorage.getItem("linkAuditId"));
    const portalSimCode = localStorage.getItem("portalSimCode");
    const showPreSimFeedbackPage = JSON.parse(
      localStorage.getItem("showPreSimFeedbackPage")
    );
    const showPostSimFeedbackPage = JSON.parse(
      localStorage.getItem("showPostSimFeedbackPage")
    );
    const showPreSimQR = JSON.parse(localStorage.getItem("showPreSimQR"));
    const showPostSimQR = JSON.parse(localStorage.getItem("showPostSimQR"));
    setSimToPlay(
      sim,
      isStoreSimSession,
      playerState.linkId,
      linkAuditId,
      accountId,
      portalSimCode,
      showPreSimFeedbackPage,
      showPostSimFeedbackPage,
      showPreSimQR,
      showPostSimQR
    );
    setShowResetPopup(false);
    trackIndex.current = -1;
    setRestartSimulation(true);
    setSimState(ssoContext, userContext, setPlayerState, setLinkId);
    setEndSimSession(false);
  };

  const closeSimulation = () => {
    window.location.replace(`${ELS_PORTAL_URL}/visiting`);
  };

  const setViewedTrackList = (viewedTrackList) => {
    let curPlayState = playerState;
    curPlayState.viewedTrackList = viewedTrackList;
    setPlayerState({ ...curPlayState });
  };
  const setShowCharacterList = (showCharacterList) => {
    let curPlayState = playerState;
    curPlayState.showCharacterList = showCharacterList;
    setPlayerState({ ...curPlayState });
  };
  const setShowInbox = (showInbox) => {
    let curPlayState = playerState;
    curPlayState.showInbox = showInbox;
    setPlayerState({ ...curPlayState });
  };
  const setShowSMS = (showSMS) => {
    let curPlayState = playerState;
    curPlayState.showSMS = showSMS;
    setPlayerState({ ...curPlayState });
  };
  const setShowCallLog = (showCallLog) => {
    let curPlayState = playerState;
    curPlayState.showCallLog = showCallLog;
    setPlayerState({ ...curPlayState });
  };
  const setShowWelcome = (showWelcome) => {
    let curPlayState = playerState;
    curPlayState.showWelcome = showWelcome;
    setPlayerState({ ...curPlayState });
  };
  const setCurrentTrack = (currentTrack, trackIdx) => {
    let curPlayState = playerState;
    curPlayState.currentTrack = currentTrack;
    curPlayState.trackIdx = trackIdx;
    setPlayerState({ ...curPlayState });
  };
  const setTrackIdx = (trackIdx) => {
    let curPlayState = playerState;
    curPlayState.trackIdx = trackIdx;
    setPlayerState({ ...curPlayState });
  };
  const setSimRunComplete = () => {
    let curPlayState = playerState;
    curPlayState.isComplete = true;
    setPlayerState({ ...curPlayState });
  };

  const setCurrentCharacter = (currentCharacter) => {
    let curPlayState = playerState;
    curPlayState.currentCharacter = currentCharacter;
    setPlayerState({ ...curPlayState });
  };
  const setCharacterIdx = (characterIdx) => {
    let curPlayState = playerState;
    curPlayState.characterIdx = characterIdx;
    setPlayerState({ ...curPlayState });
  };
  const setResponseList = (newResp) => {
    let curPlayState = playerState;
    curPlayState.responseList = newResp;
    setPlayerState({ ...curPlayState });
  };

  const setSelectedOptionsList = (selectedOptions) => {
    let curPlayState = playerState;
    curPlayState.selectedOptions = [...new Set(selectedOptions)];
    setPlayerState({ ...curPlayState });
  };

  const setCallList = (callList) => {
    let curPlayState = playerState;
    curPlayState.callList = callList;
    setPlayerState({ ...curPlayState });
  };

  const setInbox = (inbox) => {
    let curPlayState = playerState;
    curPlayState.inbox = inbox;
    setPlayerState({ ...curPlayState });
  };

  const setSMS = (sms) => {
    let curPlayState = playerState;
    curPlayState.sms = sms;
    setPlayerState({ ...curPlayState });
  };

  const setplayerBG = (playerBG) => {
    let curPlayState = playerState;
    curPlayState.playerBG = playerBG;
    setPlayerState({ ...curPlayState });
  };

  const manipulate = {
    "=": (a, b) => {
      return b;
    },
    "+": (a, b) => {
      console.debug("Sum--------------------", a, b);
      return a + b;
    },
    "-": (a, b) => {
      console.debug("Substract--------------------", a, b);
      return a - b;
    },
    "*": (a, b) => {
      return a * b;
    },
    "++": (a, b) => {
      return a++;
    },
    "--": (a, b) => {
      return a--;
    },
  };

  //get sim play time
  useEffect(() => {
    var date = new Date();
    var currentTime = date.getTime();
    setSimPlayTime(currentTime);
    console.debug(
      `Player.js UseEffect: SimLinkId=[${playerState.linkId}] , AccountId=[${playerState.accountId}]`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //change slide as index changes
  useEffect(() => {
    //Did mount set first sldie.
    //Check visibility of slide and set current
    if (playerState.trackIdx > -1) {
      if (playerState.trackIdx < playerState.tracks.length) {
        for (
          let i = playerState.trackIdx;
          i <= playerState.tracks.length;
          i++
        ) {
          if (!playerState.tracks[i].flashTrack) {
            if (playerState.tracks[i].type === "question") {
              //filter Options on the bases of option visibility settings
              let displayOptions = filterOptionVisibiltySettings(
                playerState.tracks[i]
              );
              playerState.tracks[i] = {
                ...playerState.tracks[i],
                displayOptions: displayOptions,
              };
            }
            if (showSlide(playerState.tracks[i])) {
              setCurrentTrack(playerState.tracks[i], i);
            } else {
              let tempPlayerState = playerState;
              tempPlayerState.trackId = i;
              tempPlayerState.currentTrack = playerState.tracks[i];
              getNextTrackIndex(true, tempPlayerState);
            }
            break;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerState.trackIdx, playerState.tracks]);

  useEffect(() => {
    if (isStoreSimSession && restartSimulation) {
      checkSimSessionArgument();
      setRestartSimulation(false);
    }
  }, [isStoreSimSession, restartSimulation]);

  useEffect(() => {
    if (isStoreSimSession) {
      checkSimSessionArgument();
    }
  }, [
    playerState.trackIdx,
    playerState.characterIdx,
    isStoreSimSession,
    playerState.showPreSimQR,
    playerState.showPreSimFeedbackPage,
    endSimSession,
  ]);

  useEffect(() => {
    if (isStoreSimSession && simSession) {
      checkSimSessionArgument();
    }
  }, [simResult, tracksViewedSummmary]);

  const checkSimSessionArgument = () => {
    if (playerState.trackIdx === playerState.tracks.length && endSimSession) {
      saveSimSession("completed");
    } else {
      saveSimSession("play_again");
    }
  };

  const handleShowPreSimFeedbackPage = (showPreSimFeedbackPage) => {
    setPlayerState((prevState) => {
      return { ...prevState, showPreSimFeedbackPage: showPreSimFeedbackPage };
    });
  };

  const handleShowPostSimFeedbackPage = (showPostSimFeedbackPage) => {
    setPlayerState((prevState) => {
      return { ...prevState, showPostSimFeedbackPage: showPostSimFeedbackPage };
    });
  };

  const handleShowPostSimQR = (showPostSimQR) => {
    setPlayerState((prevState) => {
      return { ...prevState, showPostSimQR: showPostSimQR };
    });
  };

  const handleShowPreSimQR = (showPreSimQR) => {
    setPlayerState((prevState) => {
      return { ...prevState, showPreSimQR: showPreSimQR };
    });
  };

  const addSimPreFeedback = (simPreFeedback) => {
    setPlayerState((prevState) => {
      return { ...prevState, simPreFeedback: simPreFeedback };
    });
  };

  const saveSimSession = (playProcessStatus) => {
    let simSessionState =
      playProcessStatus === "play_again"
        ? JSON.stringify({
            playStartDate:
              simSession?.id == undefined
                ? new Date()
                : JSON.parse(simSession?.simSessionData)?.playStartDate,
            playerState: playerState,
            simResultData: simResult,
            tracksViewedSummmary: tracksViewedSummmary,
            adContent: ssoContext?.adContent,
          })
        : JSON.stringify({});
    let session = {};
    if (simSession && simSession.id) {
      let saveFlagValue = saveFlag + 1;
      setSaveFlag(saveFlagValue);
      session = {
        ...simSession,
        simSessionData: simSessionState,
        playProcess: playProcessStatus,
        linkAuditId: playerState.linkAuditId,
        saveFlag: saveFlagValue,
      };
      SimSessionService()
        .saveOrUpdateSimSession(session)
        .then((res) => {
          setSimSession(res);
          if (
            fileSavedFlag !== -100 &&
            (res.saveFlag > fileSavedFlag || res.saveFlag === -100)
          ) {
            fileSavedFlag = res.saveFlag;
            setSavedFlag(fileSavedFlag);
          }
        });
    } else if (!isSimSessionSave) {
      isSimSessionSave = true;
      session = {
        linkId: playerState.linkId ? playerState.linkId : null,
        accountId: playerState.accountId,
        userId: userContext.user.id,
        simId: playerState.simId,
        linkAuditId: playerState.linkAuditId,
        playProcess: playProcessStatus,
        saveFlag: saveFlag,
        simSessionData: simSessionState,
      };
      SimSessionService()
        .saveOrUpdateSimSession(session)
        .then((res) => {
          setSimSession(res);
          if (
            fileSavedFlag !== -100 &&
            (res.saveFlag > fileSavedFlag || res.saveFlag === -100)
          ) {
            fileSavedFlag = res.saveFlag;
            setSavedFlag(fileSavedFlag);
          }
        });
    }
  };

  /**
   * This method will filter the options on the basses of variables and prior tracks options
   *
   * @param {*} track
   * @returns new display list of options
   */
  const filterOptionVisibiltySettings = (track) => {
    let displayOptions = [];

    if (isFromBackTrack) {
      let selectedOptions = playerState.selectedOptions;
      selectedOptions.splice(selectedOptions.length - 1);
      setSelectedOptionsList(selectedOptions);
    }

    if (!track.checkPriorOptions) {
      if (track.optionVisibilitySettings.length > 0) {
        track.optionVisibilitySettings.forEach((setting) => {
          const variable = playerState.vars.filter((variable) => {
            return variable.id === setting.variableId.value;
          });

          if (variable[0]) {
            if (evaluateVariable(variable[0], track.title, setting)) {
              for (let i = 0; i <= track.options.length; i++) {
                if (track.options[i].id === setting.optionId.value) {
                  displayOptions.push(track.options[i]);
                  break;
                }
              }
            }
          }
        });
      } else {
        setSelectedOptionsList([]);
      }
    } else {
      track.options.forEach((option) => {
        if (!playerState.selectedOptions.includes(option.response)) {
          displayOptions.push(option);
        }
      });
    }
    return [...new Set(displayOptions)];
  };

  const showSlide = (track) => {
    console.debug(
      `Player.js showSlide: SimLinkId=[${playerState.linkId}] , AccountId=[${playerState.accountId}]`
    );
    //Check if parent was set, and parent has been shown
    if (!isNullOrEmpty(track.parent) && playerState.viewedTrackList != null) {
      /* if (!playerState.viewedTrackList.includes(track.parent)) {
        console.log(
          "show false due to parent ",
          track.title,
          "parent: ",
          track.parent
        );
        */

      let found = false;

      playerState.viewedTrackList.forEach((item) => {
        if (item.id === track.parent) {
          found = true;
        }
      });
      if (!found) {
        return false;
      }
    }

    //Uses "OR" logic to see if any vis settings have been met, showing the track if any have been
    let show = true;
    let andLogicResults = [];
    if (track.visibilitySettings) {
      for (let i = 0; i < track.visibilitySettings.length; i++) {
        const vizSetting = track.visibilitySettings[i];
        const v = playerState.vars.filter((v) => {
          return v.id === vizSetting.var.value;
        });

        //if found the metadata var in the current state
        if (v[0]) {
          show = evaluateVariable(v[0], track.title, vizSetting);

          if (vizSetting.logic === "OR") {
            if (show) {
              break;
            } else {
              //check and expression
              if (andLogicResults.length > 0) {
                show = evaluateTrue(andLogicResults);
                andLogicResults = [];
                if (show) {
                  break;
                }
              }
            }
          } else if (vizSetting.logic === "AND") {
            andLogicResults.push(show);
          }
        }
      }
      if (andLogicResults.length > 0) {
        show = evaluateTrue(andLogicResults);
        andLogicResults = [];
      }
    }
    return show;
  };

  // for evaluation of array whether it has all elements are having true value
  const evaluateTrue = (array) => {
    let result = array.every((element) => element === true);
    return result;
  };

  //get the bg image from src and set state
  useEffect(() => {
    if (
      playerState.id &&
      isNullOrEmpty(playerState.playerBG) &&
      !isNullOrEmpty(playerState.backgroundImgUrl)
    ) {
      //Maybe set intermediate variable but only set BG when on first slide to prevent flashing on load?
      setplayerBG(playerState.pubBackgroundImgUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerState]);

  /**
   * This method will check if the following coming tracks are flash or not.
   * If the track is flash then it will skip the flash track and show the next non-flash track.
   * Its also check if a track have a flash alert message then show a alert message.
   *
   * @param {Boolean} setTrackId defult true, Pass false if you didn't want to set next track index here
   * @returns index of next track
   */
  const getNextTrackIndex = (setTrackId = true, defultPlayState = null) => {
    let newIndex;
    let tempLoopIndex;
    let tempLastTrackIdx = lastTrackIdx;
    let lastIndex =
      defultPlayState !== null ? defultPlayState.trackId : playerState.trackIdx;
    let currentTrack =
      defultPlayState !== null
        ? defultPlayState.currentTrack
        : playerState.currentTrack;
    let localPlayState =
      defultPlayState !== null ? defultPlayState : playerState;

    if (
      tempLastTrackIdx.length !== localPlayState.tracks.length &&
      currentTrack !== null &&
      currentTrack !== undefined
    ) {
      for (let i = lastIndex; i <= localPlayState.tracks.length; i++) {
        tempLastTrackIdx[i] = i;
        tempLoopIndex = i;
        if (
          localPlayState.tracks[i] !== undefined &&
          localPlayState.tracks[i].id !== currentTrack.id
        ) {
          if (
            !localPlayState.tracks[i].flashTrack &&
            showSlide(localPlayState.tracks[i])
          ) {
            localPlayState.trackIdx = i;
            newIndex = localPlayState.trackIdx;
            if (setTrackId) {
              setLastTrackIdx(tempLastTrackIdx);
              setTrackIdx(newIndex);
            }
            setShowFlashAlert(true);
            break;
          }
        }
      }
    } else {
      newIndex = localPlayState.trackIdx + 1;
      if (setTrackId) {
        setTrackIdx(newIndex);
      }
    }

    if (newIndex === undefined) {
      newIndex = tempLoopIndex;
      setTrackIdx(newIndex);
    }
    return newIndex;
  };

  const handleNextSlide = () => {
    toggleVolume();
    addToTrackViewed();
    setIsFromBackTrack(false);
    if (!playerState.showWelcome) {
      if (playerState.currentTrack.type === "email") {
        setInbox(playerState.inbox.concat(playerState.currentTrack));
      }
      if (playerState.currentTrack.type === "sms") {
        setSMS(playerState.sms.concat(playerState.currentTrack));
      }
      if (playerState.currentTrack.type === "phone") {
        setCallList(playerState.callList.concat(playerState.currentTrack));
      }
      getNextTrackIndex();
    } else {
      setShowWelcome(false);
    }

    if (playerState.trackIdx === playerState.tracks.length) {
      if (isStoreSimSession && saveFlag !== savedFlag) {
        setIsLoaderOpen(true);
        return;
      }
      setSimRunComplete();
    }
  };

  const handleBackSlide = () => {
    //clear previous slide time out if exists
    toggleVolume();
    if (timeOutSlide) {
      clearTimeout(timeOutSlide);
    }

    if (!playerState.showWelcome) {
      setIsFromBackTrack(true);
      if (playerState.currentTrack.type === "email") {
        setInbox(playerState.inbox.concat(playerState.currentTrack));
      }
      if (playerState.currentTrack.type === "sms") {
        setSMS(playerState.sms.concat(playerState.currentTrack));
      }
      if (playerState.currentTrack.type === "phone") {
        setCallList(playerState.callList.concat(playerState.currentTrack));
      }
      let previousTrackIdx = playerState.trackIdx - 1;
      if (previousTrackIdx >= 0) {
        for (let i = previousTrackIdx; i <= playerState.tracks.length; i--) {
          if (i !== -1) {
            if (
              !playerState.tracks[i].flashTrack &&
              showSlide(playerState.tracks[i])
            ) {
              //check if track is question and have prior flag is true then check selected options else go back
              if (
                playerState.tracks[i].type === "question" &&
                playerState.tracks[i].checkPriorOptions
              ) {
                //if selected option array not empty then go back else do nothing
                if (playerState.selectedOptions.length > 0) {
                  setIndexForBackSlide(i);
                  break;
                }
              } else {
                setIndexForBackSlide(i);
                break;
              }
            }
          } else {
            backToCharactersTracks();
            break;
          }
        }
      } else {
        backToCharactersTracks();
      }
    } else {
      setShowWelcome(false);
    }
  };

  const setIndexForBackSlide = (i) => {
    setTrackIdx(i);
    let newTrackIdx = lastTrackIdx;
    const index = newTrackIdx.findIndex(
      (idx) => idx === lastTrackIdx[lastTrackIdx.length - 1]
    );
    if (index === -1) return;
    newTrackIdx.splice(index, 1);
    setLastTrackIdx([...newTrackIdx]);
    setShowFlashAlert(true);
  };

  const backToCharactersTracks = () => {
    setCurrentTrack(null, -1);
    setCharacterIdx(0);
    setShowFlashAlert(true);
  };

  const addToTrackViewed = () => {
    if (playerState.currentTrack) {
      //current track is null until we get to the first authored track
      setViewedTrackList(
        playerState.viewedTrackList
          ? playerState.viewedTrackList.concat(playerState.currentTrack)
          : [playerState.currentTrack]
      );
    }
  };

  const handleClosePopup = () => {
    setShowCharacterList(false);
    setShowInbox(false);
    setShowSMS(false);
    setShowCallLog(false);
    setIsShowMediaList(false);
  };

  const handleVolume = () => {
    if (!isVolumeMute) {
      setIsVolumeMute(true);
    } else {
      setIsVolumeMute(false);
    }
  };

  //change actor as actor index changes
  useEffect(() => {
    //Did mount set first sldie.
    //Check visibility of slide and set current
    if (!playerState.isLoading && !playerState.showWelcome) {
      if (
        playerState.characterIdx < playerState.characters.length &&
        playerState.showCharacterIntro
      ) {
        setCurrentCharacter(playerState.characters[playerState.characterIdx]);
      } else {
        setCurrentCharacter(null);
        if (playerState.trackIdx < 0) {
          getNextTrackIndex();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    playerState.characterIdx,
    playerState.characters,
    playerState.showWelcome,
  ]);

  useEffect(() => {
    const trackIdx = playerState.trackIdx;
    if (
      trackIdx >= 0 &&
      trackIdx < playerState.tracks.length &&
      playerState.trackIdx !== trackIndex.current
    ) {
      const res = handleTracksViewedSummary(trackIdx);

      console.debug("summary=================", tracksViewedSummmary);

      let list = [...tracksViewedSummmary, res];
      if (isStoreSimSession && !viewTrackListData && list.length > 1) {
        list.pop();
      }
      console.debug("===============================list", list);
      viewTrackListData = true;
      setTracksViewedSummary(list);
    }
    trackIndex.current = playerState.trackIdx;
  }, [playerState.trackIdx]);

  const handleTracksViewedSummary = (trackIdx) => {
    return playerState.tracks[trackIdx];
  };

  const handleNextActor = () => {
    setCharacterIdx(playerState.characterIdx + 1);
  };

  const handleBackActor = () => {
    if (playerState.characterIdx > 0) {
      setCharacterIdx(playerState.characterIdx - 1);
    } else {
      setShowWelcome(true);
      setCurrentCharacter(false);
    }
  };

  const handleNextSlideWithAddAnswer = (
    responses,
    feedback,
    timeForResponse,
    decisionTimeStarted,
    decisionTimeEnded,
    responseOptionWithId
  ) => {
    toggleVolume();
    addToTrackViewed();
    updateVarList(responses, responseOptionWithId);
    setShowFlashAlert(true);
    setIsFromBackTrack(false);

    // making the response array from list of objects having all the options
    const respArray = playerState.currentTrack.options
      ? playerState.currentTrack.options
      : [""];
    const allResponsesList = respArray.map((obj) => obj?.response);
    // filtering out the result
    const filteredOptions = allResponsesList.filter(
      (element) => !responses.includes(element)
    );
    //Add Answer to answers list
    const userResp = {
      id: playerState.currentTrack.id,
      prompt:
        playerState.currentTrack.prompt === undefined
          ? ""
          : playerState.currentTrack.prompt,
      lesson: playerState.currentTrack.lesson
        ? playerState.currentTrack.lesson
        : "",
      allResp: playerState.currentTrack.options
        ? playerState.currentTrack.options
        : [""],
      responseList: responses,
      feedbackList: feedback,
      otherChoicesList: filteredOptions,
      track: playerState.currentTrack,
      viewedTracksList: playerState.viewedTrackList,
      timeForResponse: timeForResponse,
      decisionTimeStarted: decisionTimeStarted,
      decisionTimeEnded: decisionTimeEnded,
    };
    let newRespList = playerState.responseList.concat(userResp);
    setResponseList(newRespList);

    //setting selectedOptions of question tracks
    if (playerState.currentTrack.type === "question") {
      let selectedOptions = [];
      responses.forEach((response) => {
        for (let i = 0; i < playerState.currentTrack.options.length; i++) {
          if (playerState.currentTrack.options[i].response === response) {
            selectedOptions.push(playerState.currentTrack.options[i].response);
            break;
          }
        }
      });

      setSelectedOptionsList(
        playerState.selectedOptions.concat(selectedOptions)
      );
    }

    //adjust variables based on effects
    if (playerState.currentTrack.responseLoops) {
      let newIdx = getTrackIndex(responses);
      clearViewedTracksBefore(newIdx);
      setTrackIdx(newIdx);
    } else {
      getNextTrackIndex();
    }

    saveTrackResult(
      userResp,
      playerState.linkId,
      playerState.accountId,
      playerState.linkAuditId,
      playerState.portalSimCode
    );

    if (playerState.trackIdx === playerState.tracks.length) {
      setSimRunComplete();
    }
  };

  const saveTrackResult = (
    userResponse,
    portalLinkId,
    accId,
    linkAuditId,
    portalSimCode
  ) => {
    setIsSimResultData(false);
    const responseData = {
      linkId: portalLinkId,
      accountId: accId,
      userId: userContext.user.id,
      simId: playerState.simId,
      trackId: userResponse.track.trackId,
      trackType: userResponse.track.type,
      responseType: userResponse.track.responseType,
      prompt: userResponse.prompt,
      lesson: userResponse.lesson,
      response: JSON.stringify(userResponse.responseList),
      otherResponse: JSON.stringify(userResponse.otherChoicesList),
      feedback: !_.isEmpty(userResponse.feedbackList)
        ? JSON.stringify(userResponse.feedbackList)
        : null,
      timeForResponse: userResponse.timeForResponse,
      decisionTimeStarted: userResponse.decisionTimeStarted,
      decisionTimeEnded: userResponse.decisionTimeEnded,
      linkAuditId: linkAuditId,
      portalSimCode: portalSimCode,
    };
    SimResultService()
      .saveSimResult([responseData])
      .then((res) => {
        let simResultList = [...simResult, res["data"][0]];
        setSimResult(simResultList);
        setIsSimResultData(true);
      });
  };

  const clearViewedTracksBefore = (idx) => {
    let filteredList = playerState.viewedTrackList.filter(
      (track) => track.order <= idx
    );
    setViewedTrackList(filteredList);
  };

  const getTrackIndex = (responses) => {
    let goToId = null;
    playerState.currentTrack.responseLoops.forEach((loop) => {
      responses.forEach((resp) => {
        if (resp === loop.resp) {
          goToId = loop.goTo.id;
        }
      });
    });

    let newTrackIdx;
    if (goToId) {
      for (let i = 0; i < playerState.tracks.length; i++) {
        if (playerState.tracks[i].id === goToId) {
          newTrackIdx = i;
          break;
        }
      }
    } else {
      newTrackIdx = getNextTrackIndex(false);
    }
    return newTrackIdx;
  };

  const updateVarList = (respList, responseOptionWithId) => {
    let vlist = playerState.vars;
    //reseting playerState.vars currentValues before updating on the bases of responseEffect settings variables
    playerState.currentTrack.responseEffects.forEach((responseEffect) => {
      for (let k = 0; k < vlist.length; k++) {
        let variable = vlist[k];
        if (responseEffect.var.value === variable.id && variable.currentValue) {
          if (!variable.variableContext) {
            variable.currentValue = variable.startVal;
          }
          vlist[k] = variable;
          break;
        }
      }
    });

    if (playerState.currentTrack.responseEffects) {
      for (
        let i = 0;
        i < playerState.currentTrack.responseEffects.length;
        i++
      ) {
        let responseEffect = playerState.currentTrack.responseEffects[i];

        if (respList.includes(responseEffect.resp)) {
          //Find Var in var list
          //Update Value
          for (let j = 0; j < vlist.length; j++) {
            let variable = vlist[j];
            if (responseEffect.var.value === variable.id) {
              if (variable.variableContext) {
                variable.trackValue = variable.trackValue
                  ? new Map(variable.trackValue)
                  : new Map();
                const currentTrackId = playerState.currentTrack.trackId;
                //remove the next entries from the map if this track value is stored
                if (variable.trackValue.has(currentTrackId)) {
                  let equalFlag = false;
                  variable.trackValue.forEach((value, key) => {
                    if (!equalFlag) {
                      equalFlag = key === currentTrackId ? true : false;
                      return;
                    }
                    variable.trackValue.delete(key);
                  });
                  variable.currentValue =
                    variable.trackValue.get(currentTrackId);
                } else {
                  variable.trackValue.set(
                    currentTrackId,
                    variable.currentValue
                  );
                  variable.trackValue = Array.from(variable.trackValue);
                }
              }
              let varOldValue;
              let useCurrentOrStartValue =
                variable.currentValue ?? variable.startVal ?? 0;
              if (variable.type === "number") {
                varOldValue = useCurrentOrStartValue;
                variable.currentValue = manipulate[responseEffect.operand](
                  parseInt(useCurrentOrStartValue),
                  parseInt(responseEffect.val)
                );
              } else {
                varOldValue = useCurrentOrStartValue;
                variable.currentValue = manipulate[responseEffect.operand](
                  variable.currentValue,
                  responseEffect.val
                );
              }

              variable.numResp = !variable.numResp ? 1 : variable.numResp + 1;

              const responseData = {
                trackIdentifier: playerState.currentTrack.id,
                variableIdentifier: responseEffect.var.value,
                responseOptionId: findMatchingId(
                  responseOptionWithId,
                  responseEffect.resp
                ),
                responseEffectId: responseEffect.id,
                simIdentifier: variable.simId,
                accountId: playerState.accountId,
                linkId: playerState.linkId,
                linkAuditId: playerState.linkAuditId,
                portalSimCode: playerState.portalSimCode,
                oldValue:
                  variable.type === "boolean" &&
                  varOldValue.includes("undefined")
                    ? ""
                    : varOldValue
                    ? varOldValue
                    : "",
                calculatedValue:
                  variable.type === "boolean" &&
                  variable.currentValue.includes("undefined")
                    ? ""
                    : variable.currentValue
                    ? variable.currentValue
                    : "",
                incrementValue:
                  variable.type === "number"
                    ? responseEffect.operand !== "-"
                      ? responseEffect.val
                      : 0
                    : 0,
                decrementValue:
                  variable.type === "number"
                    ? responseEffect.operand == "-"
                      ? responseEffect.val
                      : 0
                    : 0,
              };

              VariableOperationService()
                .saveVariableOperation(
                  [responseData],
                  "/variableOperation/save"
                )
                .then((response) => {
                  if (response.status === 200) {
                    setVariableOperationList((prevList) => [
                      ...prevList,
                      response.data[0],
                    ]);
                    console.debug("Variable Operation saved successfully");
                  } else {
                    console.error(
                      "Error: while saving variable operation",
                      response.message
                    );
                  }
                })
                .catch((e) => {
                  console.error(e.message);
                });

              //Calc sum and average if the variable is a number
              if (variable.type === "number") {
                variable.sum = !variable.sum
                  ? parseInt(variable.currentValue)
                  : parseInt(variable.sum) + parseInt(variable.currentValue);
                variable.avg = variable.sum / variable.numResp;
              }
            }
            vlist[j] = variable;
          }
        }
      }
    }
    setPlayerState({ ...playerState, vars: vlist });
  };

  const toggleVolume = () => {
    setIsVolumeMute(true);
    setTimeout(() => {
      setIsVolumeMute(false);
    }, 1);
  };

  const handleGoToFlashTrack = () => {
    for (let i = 0; i <= playerState.tracks.length; i++) {
      if (playerState.tracks[i].id === playerState.currentTrack.flashTrackId) {
        setShowFlashAlert(false);
        if (playerState.tracks[i].type === "question") {
          //filter Options on the bases of option visibility settings
          let displayOptions = filterOptionVisibiltySettings(
            playerState.tracks[i]
          );
          playerState.tracks[i] = {
            ...playerState.tracks[i],
            displayOptions: displayOptions,
          };
        }
        setCurrentTrack(playerState.tracks[i], playerState.trackIdx);
        break;
      }
    }
  };

  const handleGoToBackTrack = () => {
    if (playerState.tracks[playerState.trackIdx].type === "question") {
      //filter Options on the bases of option visibility settings
      let displayOptions = filterOptionVisibiltySettings(
        playerState.tracks[playerState.trackIdx]
      );
      playerState.tracks[playerState.trackIdx] = {
        ...playerState.tracks[playerState.trackIdx],
        displayOptions: displayOptions,
      };
    }
    setCurrentTrack(
      playerState.tracks[playerState.trackIdx],
      playerState.trackIdx
    );
  };

  const simPlayButton = () => {
    return (
      <div className="loadMessage">
        <div className="overlay">
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <PlayButton
              style={{
                fontSize: 80,
                cursor: "pointer",
              }}
              onClick={() => {
                toggleVolume();
                setShowPlayButton(false);
                setPlay((preState) => {
                  return !preState;
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const getTimeFromSimulationProgress = (timer) => {
    setTimer(timer);
  };

  const showAd = adContent && adContent != "null" ? true : false;
  const showFeedback = playerState.trackIdx === playerState.tracks.length;
  const conditionalRenderingOfAd =
    showAd && (playerState.showWelcome || showFeedback);

  const conditionalRenderingOfCssClass = () => {
    let cssClass;

    if (playerState.trackIdx !== playerState.tracks.length) {
      if (!playerState.published) {
        cssClass = "player_content_main";
      } else {
        if (conditionalRenderingOfAd) {
          if (playerState.showWelcome) {
            cssClass = "player_content_main_publish_wid_wlcm_track";
          } else {
            cssClass = "player_content_main_publish";
          }
        } else {
          cssClass = "player_content_main";
        }
      }
    } else {
      cssClass = !conditionalRenderingOfAd
        ? "player_content_main_end_track"
        : "player_content_main_end_track_with_ad";
    }

    return cssClass;
  };

  const conditionalRenderingOfTopNav = () => {
    let topNavClass = "player_top_nav_with_ad";
    if (!conditionalRenderingOfAd) {
      if (playerState.trackIdx !== playerState.tracks.length) {
        topNavClass = "player_top_nav";
      }
      if (
        playerState.showWelcome ||
        playerState.trackIdx === playerState.tracks.length
      ) {
        topNavClass = "player_top_nav";
      }
    }
    return topNavClass;
  };

  return (
    <>
      {/*pre sim feedback page*/}
      {playerState.showPreSimFeedbackPage && !playerState.showPreSimQR && (
        <PreSimFeedback
          handleShowPreSimFeedbackPage={handleShowPreSimFeedbackPage}
          linkId={playerState.linkId}
          linkAuditId={playerState.linkAuditId}
          addSimPreFeedback={addSimPreFeedback}
        />
      )}

      {playerState.showPreSimQR && !playerState.showPreSimFeedbackPage && (
        <QRCodeGenerator
          handleNext={handleShowPreSimQR}
          value={`${SB_URL}/public/pre-feedback/${playerState.linkId}/${playerState.linkAuditId}`}
          expirytimer={qrExpiryTime - currentTime}
        />
      )}

      {/*for sim play*/}
      {!playerState.showPreSimQR &&
        !playerState.showPreSimFeedbackPage &&
        (!(playerState.showPostSimFeedbackPage || playerState.showPostSimQR) ||
          playerState.trackIdx !== playerState.tracks.length) && (
          <>
            {isLoaderOpen && (
              <div className="loader">
                <div className="loadMessage">
                  <div>
                    <h1 className="text-dark">
                      Some request are in process, So please wait until the
                      loader is gone.
                    </h1>
                    <Loader
                      type="Bars"
                      color="#272370"
                      height={200}
                      width={200}
                    />
                  </div>
                </div>
              </div>
            )}
            {!isLoaderOpen && (
              <div
                className={
                  conditionalRenderingOfAd
                    ? "player-container"
                    : "player-container player-height"
                }
              >
                <div
                  key={`sim-player-id-${playerState.id}`}
                  className="player"
                  style={{
                    backgroundImage: "url(" + playerState.playerBG + ")",
                  }}
                >
                  <input
                    type="hidden"
                    value={playerState.id}
                    id="currentSimId"
                    name="currentSimId"
                  />
                  <div className={conditionalRenderingOfTopNav()}>
                    {playerState.trackIdx !== playerState.tracks.length && (
                      <>
                        {/* old timer commented for future use
            <Timer time={playerState.time} /> */}
                        {
                          <SimulationProgress
                            playerState={playerState}
                            getTimeFromSimulationProgress={
                              getTimeFromSimulationProgress
                            }
                          />
                        }
                      </>
                    )}
                  </div>

                  <div
                    className={
                      !conditionalRenderingOfAd &&
                      playerState.trackIdx === playerState.tracks.length
                        ? "player_content_without_ad"
                        : "player_content"
                    }
                  >
                    <div className="row">
                      <div className="col-12">
                        <div
                          className={
                            conditionalRenderingOfAd
                              ? "sidebar_with_ad"
                              : "sidebar"
                          }
                        >
                          <IconButton
                            onClick={() => {
                              setIsShowMediaList(true);
                              setShowCharacterList(false);
                              setShowInbox(false);
                              setShowSMS(false);
                              setShowCallLog(false);
                            }}
                          >
                            <CustomizedIcons
                              src={DocumentsIcon}
                              title="File Cabinet"
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setShowCharacterList(
                                !playerState.showCharacterList
                              );
                              setIsShowMediaList(false);
                              setShowInbox(false);
                              setShowSMS(false);
                              setShowCallLog(false);
                            }}
                          >
                            <CustomizedIcons
                              src={CharacterIcon}
                              title="Characters Preview"
                            />
                          </IconButton>
                          {playerState.showEmailReview && (
                            <IconButton
                              onClick={() => {
                                setShowInbox(!playerState.showInbox);
                                setShowSMS(false);
                                setShowCharacterList(false);
                                setIsShowMediaList(false);
                                setShowCallLog(false);
                              }}
                            >
                              <CustomizedIcons src={MailIcon} title="Email" />
                            </IconButton>
                          )}
                          {playerState.showSMSReview && (
                            <IconButton
                              onClick={() => {
                                setShowSMS(!playerState.showSMS);
                                setShowInbox(false);
                                setShowCharacterList(false);
                                setIsShowMediaList(false);
                                setShowCallLog(false);
                              }}
                            >
                              <CustomizedIcons src={SMSIcon} title="SMS" />
                            </IconButton>
                          )}
                          {playerState.showPhoneReview && (
                            <IconButton
                              onClick={() => {
                                setShowCallLog(!playerState.showCallLog);
                                setShowInbox(false);
                                setShowSMS(false);
                                setShowCharacterList(false);
                                setIsShowMediaList(false);
                              }}
                            >
                              <CustomizedIcons
                                src={PhoneIcon}
                                title="Phone Call"
                              />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() =>
                              window.open(HELP_PAGE_LINK, "_blank")
                            }
                          >
                            <CustomizedIcons src={HelpIcon} title="Help" />
                          </IconButton>

                          <IconButton
                            id="player-mute-btn"
                            onClick={handleVolume}
                          >
                            {!isVolumeMute && (
                              <CustomizedIcons
                                src={VolumeIcon}
                                title="Turn Off Volume"
                              />
                            )}
                            {isVolumeMute && (
                              <CustomizedIcons
                                src={VolumeMute}
                                title="Turn On Volume"
                              />
                            )}
                          </IconButton>

                          <IconButton onClick={() => setShowResetPopup(true)}>
                            <CustomizedIcons
                              src={RestartIcon}
                              title="Restart Simulation"
                            />
                          </IconButton>

                          {playerState.linkId !== "" &&
                            playerState.linkId !== null &&
                            playerState.linkId !== 0 && (
                              <IconButton onClick={() => simCloseHandler(true)}>
                                <CustomizedIcons
                                  src={CloseIcon}
                                  title="Close Simulation"
                                />
                              </IconButton>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className={conditionalRenderingOfCssClass()}>
                      {!showPlayButton &&
                        playerState.currentTrack &&
                        playerState.currentTrack.flashTrack && (
                          <div className="text-right-freetext">
                            <IconButton onClick={() => handleGoToBackTrack()}>
                              <LeftArrow fontSize="large" />
                            </IconButton>
                          </div>
                        )}
                      {showPlayButton && simPlayButton()}
                      {!playerState.currentTrack &&
                        playerState.showWelcome &&
                        !playerState.isLoading && (
                          <Welcome
                            debug={false}
                            simId={playerState.id}
                            title={playerState.title}
                            desc={playerState.desc}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                            welcomeAudioUrl={playerState.pubWelcomeAudioUrl}
                            isPub={playerState.published}
                          />
                        )}
                      {!playerState.currentTrack &&
                        playerState.currentCharacter && (
                          <Character
                            key={playerState.currentCharacter.id}
                            handleNextActor={handleNextActor}
                            {...playerState.currentCharacter}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                          />
                        )}
                      {playerState.currentTrack &&
                        playerState.trackIdx !== playerState.tracks.length &&
                        playerState.currentTrack.type === "video" && (
                          <Video
                            key={playerState.currentTrack.id}
                            handleNextSlide={handleNextSlide}
                            handleGoToFlashTrack={handleGoToFlashTrack}
                            {...playerState.currentTrack}
                            isPub={playerState.published}
                            trackIdx={playerState.trackIdx}
                            trackId={playerState.currentTrack.id}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                            handleSlideTimeOut={handleSlideTimeOut}
                          />
                        )}
                      {playerState.currentTrack &&
                        playerState.tracks &&
                        playerState.trackIdx !== playerState.tracks.length &&
                        playerState.currentTrack.type === "pdf" && (
                          <PDFTrack
                            key={playerState.currentTrack.id}
                            handleNextSlide={handleNextSlide}
                            handleGoToFlashTrack={handleGoToFlashTrack}
                            {...playerState.currentTrack}
                            isPub={playerState.published}
                            trackIdx={playerState.trackIdx}
                            trackId={playerState.currentTrack.id}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                            handleSlideTimeOut={handleSlideTimeOut}
                          />
                        )}
                      {playerState.currentTrack &&
                        playerState.trackIdx !== playerState.tracks.length &&
                        playerState.currentTrack.type === "phone" && (
                          <PhoneTrack
                            key={playerState.currentTrack.id}
                            handleNextSlide={handleNextSlide}
                            handleGoToFlashTrack={handleGoToFlashTrack}
                            {...playerState.currentTrack}
                            isPub={playerState.published}
                            trackIdx={playerState.trackIdx}
                            trackId={playerState.currentTrack.id}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                            handleSlideTimeOut={handleSlideTimeOut}
                          />
                        )}
                      {playerState.currentTrack &&
                        playerState.trackIdx !== playerState.tracks.length &&
                        playerState.currentTrack.type === "text" && (
                          <Text
                            key={playerState.currentTrack.id}
                            handleNextSlide={handleNextSlide}
                            handleGoToFlashTrack={handleGoToFlashTrack}
                            {...playerState.currentTrack}
                            isPub={playerState.published}
                            trackIdx={playerState.trackIdx}
                            trackId={playerState.currentTrack.id}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                            handleSlideTimeOut={handleSlideTimeOut}
                          />
                        )}
                      {playerState.currentTrack &&
                        playerState.trackIdx !== playerState.tracks.length &&
                        playerState.currentTrack.type === "email" && (
                          <Email
                            key={playerState.currentTrack.id}
                            handleNextSlide={handleNextSlide}
                            handleGoToFlashTrack={handleGoToFlashTrack}
                            {...playerState.currentTrack}
                            isPub={playerState.published}
                            trackIdx={playerState.trackIdx}
                            trackId={playerState.currentTrack.id}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                            handleSlideTimeOut={handleSlideTimeOut}
                          />
                        )}
                      {playerState.currentTrack &&
                        playerState.trackIdx !== playerState.tracks.length &&
                        playerState.currentTrack.type === "sms" && (
                          <SMS
                            key={playerState.currentTrack.id}
                            handleNextSlide={handleNextSlide}
                            handleGoToFlashTrack={handleGoToFlashTrack}
                            {...playerState.currentTrack}
                            isPub={playerState.published}
                            trackIdx={playerState.trackIdx}
                            trackId={playerState.currentTrack.id}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                            handleSlideTimeOut={handleSlideTimeOut}
                          />
                        )}
                      {playerState.currentTrack &&
                        playerState.trackIdx !== playerState.tracks.length &&
                        playerState.currentTrack.type === "question" &&
                        timer && (
                          <Question
                            key={playerState.currentTrack.id}
                            handleNextSlideWithAddAnswer={
                              handleNextSlideWithAddAnswer
                            }
                            handleGoToFlashTrack={handleGoToFlashTrack}
                            {...playerState.currentTrack}
                            isPub={playerState.published}
                            trackIdx={playerState.trackIdx}
                            trackId={playerState.currentTrack.id}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                            timer={timer}
                          />
                        )}
                      {playerState.currentTrack &&
                        playerState.trackIdx !== playerState.tracks.length &&
                        playerState.currentTrack.type === "freetext" && (
                          <FreeText
                            key={playerState.currentTrack.id}
                            handleNextSlideWithAddAnswer={
                              handleNextSlideWithAddAnswer
                            }
                            handleGoToFlashTrack={handleGoToFlashTrack}
                            {...playerState.currentTrack}
                            isPub={playerState.published}
                            trackIdx={playerState.trackIdx}
                            trackId={playerState.currentTrack.id}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                          />
                        )}
                      {playerState.currentTrack &&
                        playerState.trackIdx !== playerState.tracks.length &&
                        playerState.currentTrack.type === "scorecard" && (
                          <Scorecard
                            handleNextSlideWithAddAnswer={
                              handleNextSlideWithAddAnswer
                            }
                            handleGoToFlashTrack={handleGoToFlashTrack}
                            {...playerState.currentTrack}
                            playerStateVars={playerState.vars}
                            isPub={playerState.published}
                            trackIdx={playerState.trackIdx}
                            trackId={playerState.currentTrack.id}
                            isMuted={isVolumeMute}
                            setPlay={setPlay}
                            play={play}
                            handleSlideTimeOut={handleSlideTimeOut}
                          />
                        )}
                      {!playerState.showPostSimFeedbackPage &&
                        playerState.trackIdx === playerState.tracks.length && (
                          <End
                            answersList={playerState.responseList}
                            linkId={playerState.linkId}
                            linkAuditId={playerState.linkAuditId}
                            tracksListViewed={playerState.viewedTrackList}
                            simId={playerState.simId}
                            trackIdx={playerState.trackIdx}
                            tracksList={playerState.tracks}
                            tracksViewedSummmary={tracksViewedSummmary}
                            simCode={playerState.id}
                            simResultList={simResult}
                            playDates={simPlayStartAndEndDate}
                            simSessionStartDate={startDate}
                            conditionalRenderingOfAd={conditionalRenderingOfAd}
                            variableOperationList={variableOperationList}
                            setEndSimSession={setEndSimSession}
                          />
                        )}
                    </div>

                    {!playerState.currentTrack &&
                      playerState.currentCharacter && (
                        <div className="row">
                          <div className="col-12">
                            <div className="align-btns">
                              {!playerState.published &&
                                playerState.characterIdx !== 0 && (
                                  <Button
                                    className="nextButton"
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleBackActor}
                                  >
                                    Back
                                  </Button>
                                )}
                              <Button
                                className="nextButton float-right"
                                variant="outlined"
                                color="primary"
                                onClick={handleNextActor}
                                style={{ marginLeft: "12px", zIndex: "1000" }}
                              >
                                Next Actor
                              </Button>
                            </div>
                          </div>
                        </div>
                      )}
                    {!showPlayButton &&
                      !playerState.currentCharacter &&
                      (playerState.currentTrack == null ||
                        !playerState.currentTrack.flashTrack) && (
                        <div className="row">
                          <div className="col-12">
                            <div
                              className={
                                conditionalRenderingOfAd
                                  ? "align-btns-with-ad"
                                  : "align-btns"
                              }
                            >
                              {!playerState.published &&
                                !playerState.showWelcome &&
                                playerState.currentTrack &&
                                playerState.trackIdx !==
                                  playerState.tracks.length && (
                                  <Button
                                    className="nextButton"
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleBackSlide}
                                  >
                                    Back
                                  </Button>
                                )}
                              {((playerState.currentTrack &&
                                !playerState.currentTrack.slideTimeout &&
                                playerState.currentTrack.type !== "freetext" &&
                                playerState.currentTrack.type !== "question") ||
                                playerState.showWelcome) &&
                                playerState.trackIdx !==
                                  playerState.tracks.length && (
                                  <Button
                                    className="nextButton float-right"
                                    variant="outlined"
                                    color="primary"
                                    onClick={handleNextSlide}
                                    style={{ marginLeft: "20px" }}
                                  >
                                    Next
                                  </Button>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    <Dialog
                      open={showResetPopup}
                      onClose={() => setShowResetPopup(false)}
                      aria-labelledby="customized-dialog-title"
                    >
                      <DialogTitle>Reset Simulation</DialogTitle>
                      <DialogContent>
                        <Typography gutterBottom>
                          Are you sure you'd like to start over at the
                          beginning?
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setShowResetPopup(false);
                          }}
                          color="secondary"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          onClick={resetAndPlayAgain}
                          color="primary"
                        >
                          Restart Simulation
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <Dialog
                      open={showCloseSimPopup}
                      onClose={() => setShowCloseSimPopup(false)}
                      aria-labelledby="customized-dialog-title"
                    >
                      <DialogTitle>Warning!</DialogTitle>
                      <DialogContent>
                        <Typography gutterBottom>
                          Are you sure you'd like to close this simulation?
                        </Typography>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="contained"
                          onClick={() => {
                            setShowCloseSimPopup(false);
                          }}
                          color="secondary"
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          onClick={closeSimulation}
                          color="primary"
                        >
                          Close Simulation
                        </Button>
                      </DialogActions>
                    </Dialog>
                    {isShowMediaList && (
                      <MediaPopupList handleClosePopup={handleClosePopup} />
                    )}
                    {playerState.showCharacterList && (
                      <CharacterPopupList handleClosePopup={handleClosePopup} />
                    )}
                    {playerState.showEmailReview && playerState.showInbox && (
                      <Inbox
                        emails={playerState.inbox}
                        handleClosePopup={handleClosePopup}
                      />
                    )}
                    {playerState.showSMSReview && playerState.showSMS && (
                      <SMSInbox
                        sms={playerState.sms}
                        handleClosePopup={handleClosePopup}
                      />
                    )}
                    {playerState.showPhoneReview && playerState.showCallLog && (
                      <RecentCalls
                        calls={playerState.callList}
                        handleClosePopup={handleClosePopup}
                      />
                    )}
                  </div>
                </div>
                {conditionalRenderingOfAd && (
                  <div className="additional-data">
                    <CkEditorReadOnly data={adContent} />
                  </div>
                )}
              </div>
            )}
          </>
        )}

      {/*post sim feedback page*/}
      {playerState.trackIdx === playerState.tracks.length &&
        playerState.showPostSimFeedbackPage &&
        !playerState.showPostSimQR && (
          <PostSimFeedback
            handleShowPostSimFeedbackPage={handleShowPostSimFeedbackPage}
            linkId={playerState.linkId}
            linkAuditId={playerState.linkAuditId}
            simPreFeedback={
              playerState.simPreFeedback ? playerState.simPreFeedback : {}
            }
          />
        )}
      {/*post sim QR page*/}
      {playerState.trackIdx === playerState.tracks.length &&
        playerState.showPostSimQR && (
          <QRCodeGenerator
            handleNext={handleShowPostSimQR}
            value={`${SB_URL}/public/post-feedback/${playerState.linkId}/${playerState.linkAuditId}`}
            expirytimer={qrExpiryTime - currentTime}
          />
        )}
    </>
  );
};

export default Player;
