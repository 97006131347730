import { useState, useContext, useEffect } from "react";
import { ArrowBackIos } from "@material-ui/icons";
import {
    Grid,
    TextField,
    Button,
    Checkbox,
    IconButton,
    Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UserContext } from "../../context/UserContext";
import StandardsService from "../../services/StandardsService";
import Select from "react-select";
import { TITLE_LENGTH_LIMITATION } from "../../utils/fieldValidation";
import { useParams } from "react-router-dom";
import { allStandards } from "../../utils/utils";
import HttpService from "../../services/HttpService";
import LoaderComponent from "../loader";

const initialState = {
    codeName: "",
    codeStandards: [],
    isSimFeedback: false,
    isSimVariable: false,
};

const customStyles = {
    menu: (provided) => ({
        ...provided,
        zIndex: 9999,
    }),
    menuList: (provided) => ({
        ...provided,
        maxHeight: "250px",
        overflowY: "auto",
    }),
    control: (provided) => ({
        ...provided,
        minHeight: "50px",
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999,
    }),
};

const CreateOrUpdateCode = (props) => {
    const { token } = useContext(UserContext);
    const history = useHistory();

    const [formState, setFormState] = useState(initialState);
    const [standards, setStandards] = useState();
    const [codeNameError, setCodeNameError] = useState(false);
    const { codeId } = useParams();
    const [isEdit, setIsEdit] = useState(
        !codeId
            ? { title: "Add", submitButtonTitle: "Create" }
            : { codeId: codeId, title: "Edit", submitButtonTitle: "Update" }
    );
    const [loading, setLoading] = useState(false);

    const backToCodesList = () => {
        history.push("/codes");
    };

    useEffect(() => {
        loadStandardsAndData();
    }, [props]);

    const loadStandardsAndData = async () => {
        try {
            setLoading(codeId ? true : false);
            // Fetch standards
            const standardsResponse = await StandardsService().getStandardsFromPortal(
                token
            );
            const fetchedStandards = standardsResponse.data.standards.map(
                (standard) => ({
                    ...standard,
                    value: standard.uuid,
                })
            );
            setStandards(fetchedStandards);

            // Fetch code data if editing
            if (codeId) {
                const codeResponse = await HttpService().getRequestById(
                    token,
                    "/codes/",
                    codeId
                );
                setLoading(false);

                // Map standards to include labels
                const updatedStandards = codeResponse?.data.codeStandards.map(
                    (standard) => {
                        const matchedStandard = fetchedStandards.find(
                            (item) => item.value === standard.uuid
                        );
                        return {
                            ...standard,
                            label: matchedStandard?.label,
                            value: standard.uuid,
                        };
                    }
                );

                setFormState({
                    ...codeResponse?.data,
                    codeStandards: updatedStandards,
                });
            }
        } catch (error) {
            console.error("Error loading data:", error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleStandardsChnage = (selectedStandards) => {
        if (
            selectedStandards &&
            selectedStandards.some((option) => option.value === "*")
        ) {
            setFormState({
                ...formState,
                codeStandards: standards,
            });
        } else if (selectedStandards?.length) {
            setFormState({
                ...formState,
                codeStandards: selectedStandards,
            });
        } else {
            setFormState({
                ...formState,
                codeStandards: [],
            });
        }
    };

    const getStandardsOptions = () => {
        if (standards != [] && standards != undefined) {
            if (formState.codeStandards?.length == standards?.length) {
                return [...formState.codeStandards];
            } else {
                return [allStandards, ...standards];
            }
        }
    };

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        if (name === "useInAccreditationFreeTextResponseScoring") {
            setFormState({
                ...formState,
                isSimFeedback: checked,
            });
        } else if (name === "useInSimVariable") {
            setFormState({
                ...formState,
                isSimVariable: checked,
            });
        }
    };

    const handleSubmit = () => {
        if (!formState.codeName.trim()) {
            setCodeNameError(true);
            return;
        }
        if (!codeNameError) {
            setLoading(true);
            HttpService()
                .postRequestWithAuthentication(formState, "/codes/saveOrUpdate", token)
                .then((response) => {
                    if (response.status === 200) {
                        setLoading(false);
                        backToCodesList();
                        console.debug("Code saved successfully");
                    }
                })
                .catch((e) => {
                    console.error("Error while saving code", e);
                });
        }
    };

    const handleCodeNameChange = (event) => {
        const newState = { [event.target.name]: event.target.value };
        setFormState({
            ...formState,
            ...newState,
        });
        if (event.target.value.trim()) {
            setCodeNameError(false);
        }
    };

    return (
        <div className="card">
            <div className="card-header color-blue">
                <h3 className="h4">
                    <IconButton onClick={backToCodesList}>
                        <ArrowBackIos fontSize="small" />
                    </IconButton>
                    {`${isEdit.title} Code`}
                </h3>
            </div>
            <div className="card-body">
                <div className="overflow-auto">
                    <div className="simBuilderForm">
                        <Grid container spacing={2} alignItems="center">
                            <Grid item xs={3}>
                                <Typography className="color-blue required">
                                    Code Name
                                </Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    error={codeNameError}
                                    variant="outlined"
                                    id="codeName"
                                    name="codeName"
                                    label="Code Name"
                                    value={formState.codeName}
                                    onChange={handleCodeNameChange}
                                    required
                                    fullWidth
                                    inputProps={{ maxLength: TITLE_LENGTH_LIMITATION }}
                                    helperText={codeNameError ? "Code Name is required." : ""}
                                    InputProps={{
                                        readOnly: !!codeId,
                                        style: codeId ? { backgroundColor: "#f0f0f0" } : {},
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            style={{ marginTop: 12 }}
                        >
                            <Grid item xs={3}>
                                <Typography className="color-blue">Standards</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <div style={{ marginTop: 8, width: "100%" }}>
                                    <Select
                                        isMulti
                                        value={formState.codeStandards}
                                        onChange={handleStandardsChnage}
                                        options={getStandardsOptions()}
                                        placeholder="Add Standard"
                                        styles={customStyles}
                                        className="w-100"
                                        menuPortalTarget={document.body}
                                    />
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            style={{ marginTop: 16 }}
                        >
                            <Grid item xs={3}></Grid>
                            <Grid item xs={9}>
                                <div className="checkbox-container">
                                    <div className="checkbox-group">
                                        <Checkbox
                                            checked={formState.isSimFeedback}
                                            onChange={handleCheckboxChange}
                                            name="useInAccreditationFreeTextResponseScoring"
                                            color="primary"
                                        />
                                        <Typography
                                            onClick={(e) => e.stopPropagation()}
                                            className="checkbox-label"
                                        >
                                            Use in Accreditation Free Text Response Scoring
                                        </Typography>
                                    </div>
                                    <div className="checkbox-group">
                                        <Checkbox
                                            checked={formState.isSimVariable}
                                            onChange={handleCheckboxChange}
                                            name="useInSimVariable"
                                            color="primary"
                                        />
                                        <Typography
                                            onClick={(e) => e.stopPropagation()}
                                            className="checkbox-label"
                                        >
                                            Use in Sim Variable
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            style={{ marginTop: 16 }}
                        >
                            <Grid item xs={3}></Grid>
                            <Grid
                                item
                                xs={9}
                                style={{ display: "flex", justifyContent: "flex-end" }}
                            >
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={backToCodesList}
                                    className="justify-content-center"
                                    name="cancel"
                                >
                                    Cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                >
                                    {isEdit.submitButtonTitle}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="overlay">
                    <LoaderComponent />
                </div>
            )}
        </div>
    );
};

export default CreateOrUpdateCode;
