import React, { useEffect, useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { Button } from "@material-ui/core";
import TopNavForPublic from "./TopNavForPublic";
import { SB_URL } from "../../Config";


const QRCodeGenerator = ({ handleNext, value, expirytimer }) => {
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    // Start session timeout countdown
    const timer = setTimeout(() => {
      setSessionExpired(true);
    }, expirytimer);

    return () => clearTimeout(timer);
  }, []);

  const onNextClick = async (e) => {
    handleNext(false);
  };

  return (
    <>
      <link href="/simFeedback.css" rel="stylesheet" />
      <TopNavForPublic />

      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <section className="simulation-header p-0">
              <h3 className="title">Learning Culture Survey</h3>
            </section>
            <p className="p-2 text-center">
              As you know, gauging professional growth can be tricky, and time
              is scarce. Completing this survey before and after the simulation
              will allow us to provide anonymous data about learning culture and
              staff development. Please have the participants scan the QR code
              with their phones and complete the brief survey, and we will share
              the anonymous data with you.
            </p>
            <div className="col-md-12 text-center">
              <QRCodeSVG
                value={sessionExpired ? `${SB_URL}/session-expired` : value}
                size={300}
              />
              <div className="d-flex justify-content-center mt-5">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={onNextClick}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCodeGenerator;
