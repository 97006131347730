import _ from "lodash";
import S3Service from "../services/S3Service";
import { Tooltip, withStyles } from "@material-ui/core";
import { Menu, MenuItem } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import Config from "../Config";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import Looks3Icon from "@material-ui/icons/Looks3";
import Looks4Icon from "@material-ui/icons/Looks4";
import Looks5Icon from "@material-ui/icons/Looks5";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";

export const defultReportPrintStyle =
  ".report {padding: 5px; font-size: 18px; word-break: break-word;}button{display:none;}.report_item {border: solid 2px #cecece;border-radius: 5px;padding: 0px 20px 10px 20px;margin-bottom: 10px; font-size: 18px}.report_item h2 {font-size: 18px}.report_item h3 {font-size: 18px; 0;padding: 0;}.report_item p {font-size: 18px; padding: 0 0 0 25px;margin: 0;} .report_item h3 {margin: 20px 0 0 0; font-size: 18px}.report_item ul {   padding: 0 0 15px 75px; margin: 0; font-size: 18px}.feedback_choice {font-size: 18px; margin-left: 15px;}.personal_info {margin-top: 12px; display: flex; flex-direction: column; flex-wrap: wrap; column-gap: 10px; row-gap: 10px; font-size: 18px} .report-item-summary { border: solid 2px #cecece; border-radius: 5px; padding: 5px; min-width: 100px; display: inline-block; margin: 10px; font-size: 18px}";

/**
 *  This method will check the val is null, empty string or undefined
 *
 * @param {*} val
 * @returns boolean
 */
export const isNullOrEmpty = (val) => {
  return val === undefined || _.isNull(val) || _.isEmpty(val);
};

/**
 * This will compare the two objects and tell us whether they are identical or not.
 *
 * @param {*} obj1 any type of value e.g, String, Array, Object ...
 * @param {*} obj2 any type of value e.g, String, Array, Object ...
 * @returns Boolean
 */
export const compareObjetcs = (obj1, obj2) => {
  return _.isEqual(obj1, obj2);
};

/**
 * This method will delete the value from array.
 *
 * @param {*} array any type of array
 * @param {*} value which you want to remove from array
 * @returns updated array
 */
export const deleteValueFromArray = (array, value) => {
  return _.without(array, value);
};

/**
 * This method will copy text to the clip board
 *
 * @param {*} text
 * @param {*} elementId
 * @returns boolean true/false
 */
export const copyTextToClipBoard = (text, elementId) => {
  document.getElementById(elementId).value = text;
  const url = document.getElementById(elementId);
  url.style.display = "inline";
  url.select();
  url.setSelectionRange(0, 99999);
  document.execCommand("copy");
  url.style.display = "none";
  return true;
};

/**
 * This method will used for making a cutome tooltip
 */
export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 600,
    border: "1px solid #dadde9",
  },
}))(Tooltip);

/**
 * This method will create document and print it.
 * @param {*} title - Document title
 * @param {*} printStyles - Pass style of document in string formate
 * @param {*} docBody - Document body data
 * @param {*} popUpHeight - Document popUp Height - Defult=500
 * @param {*} popUpWidth - Document popUp Width - Defult=900
 */
export const printDocument = (
  title,
  reportName,
  printStyles,
  docBody,
  popUpHeight = 500,
  popUpWidth = 900
) => {
  // Fixes dual-screen position Most browsers Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : window.screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : window.screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - popUpWidth) / 2 / systemZoom + dualScreenLeft;
  const top = (height - popUpHeight) / 2 / systemZoom + dualScreenTop;

  var mywindow = window.open(
    reportName,
    "",
    `height=${popUpHeight / systemZoom}, width=${
      popUpWidth / systemZoom
    }, top=${top}, left=${left}`
  );
  mywindow.document.write(`<html><head><title>${title}</title>`);
  mywindow.document.write("<style>");
  mywindow.document.write(printStyles);
  mywindow.document.write("</style>");
  mywindow.document.write("</head><body >");
  mywindow.document.write(docBody);
  mywindow.document.write("</body></html>");
  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.document.close();
  mywindow.print();
};

/**
 * This method is used to evaluate variable
 * @param variable
 * @param title
 * @param setting
 */
export const evaluateVariable = (variable, title, setting) => {
  //use current value if it's been set since start of sim since we don't copy start/current at begining
  let show = true;
  let valToCompare;
  if (variable.variableContext) {
    valToCompare = variable.currentValue ?? variable.startVal;
  } else {
    valToCompare = variable.currentValue
      ? variable.currentValue
      : variable.startVal;
  }

  //evaluate with dynamic operator
  console.debug(
    "Evaluating viz logic ",
    title,
    "  Type [" + variable.type + "] ",
    valToCompare,
    "  ",
    setting.operand,
    "  ",
    setting.val
  );

  if (
    (variable.type === "number" &&
      !evaluate[setting.operand](
        parseInt(valToCompare ? valToCompare : 0),
        parseInt(setting.val ? setting.val : 0)
      )) ||
    (variable.type !== "number" &&
      !evaluate[setting.operand](
        valToCompare.toLowerCase(),
        setting.val.toLowerCase()
      ))
  ) {
    console.debug(
      "Choose not to show track because of vizSetting ",
      title,
      valToCompare,
      "  ",
      setting.operand,
      "  ",
      setting.val
    );
    show = false;
  }
  return show;
};

//Used to dynamically evaluate variable equality
export const evaluate = {
  "==": (a, b) => {
    // eslint-disable-next-line eqeqeq
    return a == b;
  },
  "!=": (a, b) => {
    // eslint-disable-next-line eqeqeq
    return a != b;
  },
  "<": (a, b) => {
    return a < b;
  },
  "<=": (a, b) => {
    return a <= b;
  },
  ">": (a, b) => {
    return a > b;
  },
  ">=": (a, b) => {
    return a >= b;
  },
};
/**
 * Custome styled of menu
 */
export const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
));

/**
 * Custome styled of MenuItem for Menu
 */
export const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:hover": {
      backgroundColor: "rgba(213, 102, 100, 0.09)",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#D56664 !important",
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
    },
  },
}))(MenuItem);

export const checkFileType = (file) => {
  let type;
  let tempFile = null;
  let isCaptionFile = false;
  //Set file type on PC because no data is passed from windows by default for vtt file types.
  if (file.type === "" && file.name.split(".")[1] === "vtt") {
    tempFile = file.slice(0, file.size, "text/vtt");
    tempFile.name = file.name;
    isCaptionFile = true;
  }
  switch (
    isCaptionFile === true
      ? tempFile.type.toLowerCase()
      : file.type.toLowerCase()
  ) {
    case "video/mp4":
      type = "video";
      break;
    case "image/jpeg":
    case "image/png":
    case "image/gif":
      type = "image";
      break;
    case "audio/mp3":
    case "audio/mp4":
    case "audio/mpeg":
      type = "sound";
      break;
    case "application/pdf":
      type = "pdf";
      break;
    case "text/vtt":
      type = "caption";
      break;
    default:
      type = null;
      break;
  }
  return { type, tempFile };
};

export const setSimPlayData = async (
  userContext,
  ssoContext,
  simLinkData,
  linkAuditId
) => {
  const isShowPreQR =
    simLinkData?.simPlayType === "synchronous" &&
    simLinkData?.accountType !== "Higher Ed";

  const isShowPostQR = simLinkData?.simPlayType === "synchronous";

  const isShowPreFeedbackPage =
    simLinkData?.simPlayType !== "synchronous" &&
    simLinkData?.showPreSimFeedbackPage;

  const isShowPostFeedbackPage =
    simLinkData?.simPlayType !== "synchronous" &&
    simLinkData?.showPostSimFeedbackPage;

  const adContent = await setSimAdContent(
    simLinkData?.adContent,
    simLinkData.token
  );
  linkAuditId = linkAuditId ? linkAuditId : simLinkData?.linkAuditId;
  userContext.setUser(simLinkData.user);
  userContext.setToken(simLinkData.token);
  ssoContext.setLinkId(simLinkData?.linkId);
  ssoContext.setAccountId(simLinkData?.accountId);
  ssoContext.setLinkAuditId(linkAuditId);
  ssoContext.setPortalSimCode(simLinkData?.portalSimCode);
  ssoContext.setAdContent(adContent);
  ssoContext.setAccreditationClient(simLinkData?.accreditationClient);
  ssoContext.setShowPreSimFeedbackPage(isShowPreFeedbackPage);
  ssoContext.setShowPostSimFeedbackPage(isShowPostFeedbackPage);
  ssoContext.setShowPreSimQR(isShowPreQR);
  ssoContext.setShowPostSimQR(isShowPostQR);
  ssoContext.setAccountType(simLinkData?.accountType);
  ssoContext.setSsoSimId(simLinkData?.simId);
  localStorage.setItem("user", JSON.stringify(simLinkData?.user));
  localStorage.setItem("token", simLinkData.token);
  localStorage.setItem("linkId", simLinkData?.linkId);
  localStorage.setItem("accountId", simLinkData?.accountId);
  localStorage.setItem("linkAuditId", linkAuditId);
  localStorage.setItem("portalSimCode", simLinkData?.portalSimCode);
  localStorage.setItem("adContent", adContent);
  localStorage.setItem("simId", simLinkData?.simId);
  localStorage.setItem("accreditationClient", simLinkData?.accreditationClient);
  localStorage.setItem("showPreSimFeedbackPage", isShowPreFeedbackPage);
  localStorage.setItem("showPostSimFeedbackPage", isShowPostFeedbackPage);
  localStorage.setItem("showPreSimQR", isShowPreQR);
  localStorage.setItem("showPostSimQR", isShowPostQR);
  localStorage.setItem("accountType", simLinkData?.accountType);
};

export const setSimAdContent = async (adContent, token) => {
  const bucketName = Config.aws.s3.portalBucketName;
  const adContentHTML = textToDom(adContent);
  const images = adContentHTML.querySelectorAll("img");
  for (const img of images) {
    const filePath = img.getAttribute("src");
    const adContentUrl = await S3Service().getSigendUrlForView(
      filePath,
      filePath.split(".")[1],
      token,
      bucketName
    );
    img.setAttribute("src", adContentUrl.data);
  }
  return adContentHTML.body.innerHTML;
};

export const textToDom = (text) => {
  return new DOMParser().parseFromString(text, "text/html");
};

export const base64ToImage = (selectedImages) => {
  const imageFiles = Object.entries(selectedImages).map(([key, value]) => {
    const byteCharacters = atob(key.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create Blob from binary data
    const imgBlob = new Blob([byteArray], { type: "image/jpeg" });

    const uniqueName = `${uuidv4()}.jpeg`;
    const image = new File([imgBlob], uniqueName, { type: "image/jpeg" });
    image.typeField = "image";
    image.maxSize = 30;
    image.tempName = value;
    return image;
  });

  return imageFiles;
};

export const AI_IMAGES_ERROR_MSG = Object.freeze({
  alertMessage: {
    msg: "The text you entered may be considered not suitable for work. Please try to rephrase your request.",
    type: "error",
  },
  timedOutAlert: {
    msg: "Your request session timed out. Please try again and rephrase your request.",
    type: "error",
  },
  unableAlert: {
    msg: "Unable to generate images. Please try again and rephrase your request.",
    type: "error",
  },
  textValidationMessage:
    "Minimum required length for prompt is 3 characters. Please reformat your input.",
});

export const setSimState = (
  ssoContext,
  userContext,
  setPlayerState,
  setLinkId
) => {
  const linkId = localStorage.getItem("linkId");
  userContext.setUser({ ...JSON.parse(localStorage.getItem("user")) });
  userContext.setToken(localStorage.getItem("token"));
  ssoContext.setLinkId(linkId);
  ssoContext.setAccountId(localStorage.getItem("accountId"));
  ssoContext.setLinkAuditId(localStorage.getItem("linkAuditId"));
  ssoContext.setPortalSimCode(localStorage.getItem("portalSimCode"));
  ssoContext.setSsoSimId(localStorage.getItem("simId"));
  ssoContext.setAdContent(localStorage.getItem("adContent"));
  ssoContext.setAccreditationClient(
    localStorage.getItem("accreditationClient")
  );
  setLinkId(linkId);
  setPlayerState({ ...JSON.parse(localStorage.getItem("playerState")) });
};

/**
 * This will handle the copy paste action of the user
 * @param {*} event
 * @param {*} maxLength
 */
export const handlePaste = (event, maxLength) => {
  const pastedText = event.clipboardData.getData("text/plain");
  if (pastedText.length > maxLength) {
    event.preventDefault(); // Prevent default paste behavior
    const truncatedText = pastedText.substring(0, maxLength);
    document.execCommand("insertText", false, truncatedText); // Insert truncated text
  }
};

/**
 * This will handle conditional rendering of css classes
 * @param {*} isPub
 * @param {*} trackIdx
 * @returns
 */
export const getMarginTop = (isPub, trackIdx) => {
  if (trackIdx || trackIdx === 0) {
    return isPub ? "-22px" : "0px";
  } else {
    return "0px";
  }
};

export const findMatchingId = (answers, result) => {
  let id;
  for (let answer = 0; answer < answers.length; answer++) {
    if (answers[answer].option === result) {
      id = answers[answer].id;
      break;
    }
  }
  return id;
};

/**
 * This will check is the html element is equal to ancher tag if it is then it opens the link in a new tab
 * @param event
 * @param htmlElement
 */
export const openLinkNewTab = (event, htmlElement) => {
  if (htmlElement.tagName === "A") {
    event.preventDefault();
    window.open(htmlElement.href, "_blank");
  }
};

export const PersonalInfoMessage = () => {
  return (
    <>
      Please enter your information in order to have it appear on the SchoolSims
      Feedback Report. In addition, by entering your information, this report
      will be available for 7 days. If you need it again, please contact&nbsp;
      <span className="color-blue">cx@schoolsims.com</span>
    </>
  );
};

export const PersonalInfoNote = () => {
  return (
    <>
      Note: If you select Yes and play this simulation again you can override
      this response the next time you play this simulation.
    </>
  );
};

// function to remove special charcters from the keys in track object
export const removeSpecialCharcaters = (track) => {
  const newObj = {};

  // Iterate over each key in the object
  Object.keys(track).forEach((key) => {
    // Normalize the key: replace special characters with a space and trim extra spaces
    const normalizedKey = key
      .replace(/[^a-zA-Z0-9]/g, " ") // Replace non-alphanumeric characters with a space
      .replace(/\s+/g, " ") // Replace multiple spaces with a single space
      .trim(); // Trim leading/trailing spaces

    // Add the key-value pair to the new object
    newObj[normalizedKey] = track[key];
  });

  return newObj;
};

// object to map track types coming from xls file
export const TRACK_TYPE = Object.freeze({
  V: "video",
  Q: "question",
  T: "text",
  F: "freetext",
});

// Map of extensions to MIME types
export const extensionToMimeType = {
  ".png": "image",
  ".jpg": "image",
  ".jpeg": "image",
  ".gif": "image",
  ".mp3": "sound",
  ".mp4": "video",
};

// Helper function to get the file extension
export const getFileExtension = (fileName) => {
  return fileName.slice(fileName.lastIndexOf(".")).toLowerCase();
};

// Helper function to get the MIME type from the file extension
export const getMimeType = (fileName) => {
  const ext = getFileExtension(fileName);
  return extensionToMimeType[ext] || "application/octet-stream"; // Default to 'application/octet-stream' if unknown
};

// function to get capitalize the letter
export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase();
};

// Generic onKeyDown function
export const handleKeyDown = (
  event,
  actionHandler,
  newStateValue,
  ...extraArgs
) => {
  if (event.key === "Enter" || event.key === " ") {
    event.preventDefault();
    actionHandler(newStateValue, ...extraArgs);
  }
};

export const validXlsTypes = [
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
]; // Acceptable MIME types for XLS/XLSX files

export const validZipTypes = [
  "application/zip",
  "application/x-zip-compressed",
  "multipart/x-zip",
]; // Acceptable MIME types for ZIP files

export const allStandards = { value: "*", label: "Select All Standards" };

// Extraction of standard labels
export const getStandardLabels = (codeStandards, standards) => {
  return codeStandards
    .map((codeStandard) => {
      const standard = standards?.find((s) => s.uuid === codeStandard.uuid);
      return standard ? standard.label : null;
    })
    .filter(Boolean);
};

export const smileyIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon style={{ color: "#EA7F64" }} />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon style={{ color: "#F4B46C" }} />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon style={{ color: "#F5D577" }} />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon style={{ color: "#B9E38F" }} />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon style={{ color: "#7ED580" }} />,
    label: "Very Satisfied",
  },
};

export const ratingIcons = {
  1: {
    icon: <LooksOneIcon style={{ color: "#EA7F64" }} />,
    label: "Strongly disagree",
  },
  2: {
    icon: <LooksTwoIcon style={{ color: "#F4B46C" }} />,
    label: "Somewhat disagree",
  },
  3: {
    icon: <Looks3Icon style={{ color: "#F5D577" }} />,
    label: "Neither agree nor disagree",
  },
  4: {
    icon: <Looks4Icon style={{ color: "#B9E38F" }} />,
    label: "Somewhat agree",
  },
  5: {
    icon: <Looks5Icon style={{ color: "#7ED580" }} />,
    label: "Strongly agree",
  },
};
