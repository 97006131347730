import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import StaticTable from "../generic/StaticTablePagination";
import EditIcon from "@material-ui/icons/Edit";
import { Delete } from "@material-ui/icons";
import LoaderComponent from "../loader";
import StandardsService from "../../services/StandardsService";
import HttpService from "../../services/HttpService";
import { getStandardLabels } from "../../utils/utils";

const Codes = () => {
    const history = useHistory();
    const { token } = useContext(UserContext);
    const [codesData, setCodesData] = useState([]);
    const [standards, setStandards] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        loadData();
    }, []);

    const loadData = async () => {
        StandardsService()
            .getStandardsFromPortal(token)
            .then((response) => {
                setStandards(
                    response.data?.standards.map((standard) => {
                        return { ...standard, value: standard.uuid };
                    })
                );
            })
            .catch((e) => {
                console.debug("Error Occurred", e.message);
            });

        HttpService()
            .getRequest(token, "/codes")
            .then((response) => {
                setCodesData(response.data);
                setLoading(false);
            })
            .catch((e) => {
                console.debug("Error occurred while getting the codes", e.message);
            });
    };

    const columns = [
        {
            dataField: "codeName",
            text: "Code Name",
            sort: true,
            classes: "content-body title",
            headerClasses: "table_header_color",
            align: "left",
            headerStyle: { minWidth: 320 },
            formatter: (cell, record) => <p>{record.codeName}</p>,
        },
        {
            dataField: "standards",
            text: "Standards",
            sort: true,
            classes: "content-body title",
            headerClasses: "table_header_color",
            align: "left",
            headerStyle: { minWidth: 500 },
            formatter: (cell, record) => {
                const standardLabels = getStandardLabels(
                    record.codeStandards,
                    standards
                );
                return <p>{standardLabels.join(", ")}</p>;
            },
        },
        {
            dataField: "action",
            text: "Actions",
            classes: "content-body",
            headerClasses: "text-center",
            align: "center",
            headerStyle: { width: 170 },
            formatter: (cell, edited) => {
                return (
                    <>
                        <Tooltip title="Edit Code" placement="top">
                            <Link to={`/editCode/${edited.id}`}>
                                <EditIcon color="primary" />
                            </Link>
                        </Tooltip>
                        <Tooltip title="Delete Code" placement="top">
                            <IconButton
                            // onClick={() => handleDelete(record)}
                            >
                                <Delete color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];

    return (
        <div className="card">
            <div className="card-header">
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="h4 color-blue">Accreditation Codes</h3>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            history.push("/addCode");
                        }}
                    >
                        Add Code
                    </Button>
                </div>
            </div>

            <div className="card-body">
                <div className="overflow-auto">
                    <StaticTable
                        data={codesData}
                        columns={columns}
                        id="codeId"
                        select={false}
                    />
                </div>
            </div>
            {loading && (
                <div className="overlay">
                    <LoaderComponent />
                </div>
            )}
        </div>
    );
};

export default Codes;
